<template>
  <SectionOne ></SectionOne>
  <SectionTwo class="padding-sectiontwo" ></SectionTwo>
  <SectionThree class="padding-sectiontwo" ></SectionThree>
  <JoinSections class="padding-sectiontwo" ></JoinSections>
  <SectionSix class="padding-sectiontwo" />
  <SectionSeven class="padding-sectionSeven" />
  <SectionEigth class="padding-sectiontwo" />
  <SectionNine class="padding-sectiontwo" />
</template>

<script>
import SectionOne from '../sectionOne/SectionOne.vue';
import SectionTwo from '../sectionTwo/SectionTwo.vue';
import SectionThree from '../sectionThree/SectionThree.vue';
import JoinSections from '../joinSectionFourFive/JoinSections.vue'
import SectionSix from '../sectionSix/SectionSix.vue';
import SectionSeven from '../sectionSeven/SectionSeven.vue';
import SectionEigth from '../sectionEigth/SectionEigth.vue';
import SectionNine from '../sectionNine/SectionNine.vue';

export default {
  name: 'App',
  components: {
    SectionOne,
    SectionTwo,
    SectionThree,
    JoinSections,
    SectionSix,
    SectionSeven,
    SectionEigth,
    SectionNine
  }
}
</script>
