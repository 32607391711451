<template>
    <!-- Content for desktop -->
    <aside class="utils-s1--tablet">
        <aside class="header--not-found-grid header--padding cursor--pointer" :onClick="goToHome">
            <img class="padding-s1-logo--resized" alt="Logo Orketra" src="../../assets/Logo.png" />
        </aside>
        <aside class="content--not-found-grid header--padding container-notFound--background">
            <div class="not-found-content--container ---padding--force--notFound">
                <p :style="{ fontFamily: 'BrotherBold', fontSize: '60pt', fontWeight: 'bold' }">{{ $t("message.notFound--p1") }}</p>
                <div :style="{ fontFamily: 'BrotherRegular', paddingTop: '10%' , fontSize: '30pt'}">{{ $t("message.notFound--p2") }}<br> {{ $t("message.notFound--p3") }} <span :style="{ fontFamily: 'BrotherBlod', fontWeight: '700' }">{{ $t("message.notFound--p4") }}<br> {{ $t("message.notFound--p5") }}</span></div>
            </div>
            <Vue3Lottie class="card--size cursor--pointer" :animationData="gearsJson" :autoPlay="true" />
        </aside>
    </aside>
    <!-- Content for mobile -->
    <aside class="utils-desktop">
        <aside class="header--not-found-grid header--padding cursor--pointer" :onClick="goToHome">
            <img class="padding-s1-logo--resized" alt="Logo Orketra" src="../../assets/Logo.png" />
        </aside>
        <div class="container-notFound--background">
            <Vue3Lottie class="resized--gear cursor--pointer" :animationData="gearsJson" :autoPlay="true" />
        </div>
        <div class="not-found-content--container">
            <p class="styled---mobile-not-found">{{ $t("message.notFound--p1") }}</p>
            <div class="styled---mobile-second-not-found">{{ $t("message.notFound--p2") }}<br> {{ $t("message.notFound--p3") }} <span class="styled--mobile--text-force--information">{{ $t("message.notFound--p4") }}<br> {{ $t("message.notFound--p5") }}</span></div>
        </div>
    </aside>
</template>

<script>
import Gears from '../../assets/lottieAnimations/Gears.json'

export default {
    name: 'NotFoundPage',
    data() {
        return {
            gearsJson: Gears
        }
    },
    methods: {
        goToHome() {
            this.$router.push('/')
        }
    }
}
</script>

<style>
.resized--gear {
    width: 400px;
    height: 400px;
}
.styled---mobile-not-found {
    font-family: 'BrotherBlod';
    font-size: 35pt;
    font-weight: bold;
}
.styled--mobile--text-force--information {
    font-family: 'BrotherBlod' !important;
    font-weight: 700;
}
.styled---mobile-second-not-found {
    font-family: 'BrotherRegular';
    font-size: 20pt;
    padding-top: 15%;
}
.---padding--force--notFound {
    margin-top: 20%;
}
.container-notFound--background {
    background-image: url('../../assets/NotFoundBackground.png');
}
.header--not-found-grid {
    display: grid;
    grid-template-columns: 180px 1fr;
    margin: 40px
}
.content--not-found-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.not-found-content--container {
    text-align: left;
    align-self: center;
    color: black;
    line-height: 35px;
    white-space: nowrap;
}
@media (max-width: 600px) {
    .header--not-found-grid {
        margin: 40px 0px 40px 40px
    }
    .not-found-content--container {
        text-align: left;
        padding: 63px;
        line-height: 30px;
    }   
}
</style>
