<template>
    <div class="card" :style="cssProps">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'CardContainer',
        props: {
          small: {
            type: Boolean,
            default: false
          },
          normal: {
            type: Boolean,
            default: true
          },
        },
        computed: {
          cssProps() {
            if (this.small) {
              return {
                '--width': '966px'
              }
            }

            return {
              '--width': '1300px'
            }
          }
        }
    }
</script>

<style>
.card {
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
  transition: 0.3s;
  width: var(--width);
  background-color: white;
  padding: 60px;
  border-radius: 25px;
}
.card:hover {
  box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.2);
}

@media (max-width: 1280px) {
  .card {
    width: 800px;
  }
}
@media (max-width: 1010px) {
  .card {
    width: 550px;
  }
}
@media (max-width: 800px) {
  .card {
    width: 350px;
  }
}
@media (max-width: 484px) {
  .card {
    width: 80vw;
    padding: 20px;
  }
}
</style>