<template>
    <aside class="two-card--section">
        <section class="inner-card">
            <div class="card--container">
                <p class="text-header-card--styled align--flex-start --no-margin">{{ $t("message.s2-card2-title") }}</p>
                <Vue3Lottie :key="autoPlayStats" v-observe-visibility="visibilityStatsChanged" class="img--sized-s2tc cursor--pointer" :loop="false" :animationData="statsJson" :autoPlay="autoPlayStats" />
                <p class="--no-margin s2--two-display inner-container-card-s2-text">{{ $t("message.s2-card2-subtitle--desktop-p1") }}<br> {{ $t("message.s2-card2-subtitle--desktop-p2") }}<br> {{ $t("message.s2-card2-subtitle--desktop-p3") }}</p>
                <p class="--no-margin s2--show-display inner-container-card-s2-text">{{ $t("message.s2-card2-subtitle--tablet-p1") }}<br> {{ $t("message.s2-card2-subtitle--tablet-p2") }}<br> {{ $t("message.s2-card2-subtitle--tablet-p3") }}<br> {{ $t("message.s2-card2-subtitle--tablet-p4") }}<br>{{ $t("message.s2-card2-subtitle--tablet-p5") }}</p>
            </div>
        </section>
        <section class="inner-card">
            <div class="card--container">
                <p class="text-header-card--styled align--flex-start --no-margin">{{ $t("message.s2-card3-title") }}</p>
                <Vue3Lottie :key="autoPlayReporte" v-observe-visibility="visibilityReporteChanged" class="img--sized-s2tc cursor--pointer" :loop="false" :animationData="reporteJson" :autoPlay="autoPlayReporte" />
                <p class="--no-margin s2--two-display inner-container-card-s2-text">{{ $t("message.s2-card3-subtitle--desktop-p1") }}<br>{{ $t("message.s2-card3-subtitle--desktop-p2") }}<br>{{ $t("message.s2-card3-subtitle--desktop-p3") }}</p>
                <p class="--no-margin s2--show-display inner-container-card-s2-text">{{ $t("message.s2-card3-subtitle--tablet-p1") }}<br> {{ $t("message.s2-card3-subtitle--tablet-p2") }}<br> {{ $t("message.s2-card3-subtitle--tablet-p3") }}<br> {{ $t("message.s2-card3-subtitle--tablet-p4") }}<br>{{ $t("message.s2-card3-subtitle--tablet-p5") }}<br> {{ $t("message.s2-card3-subtitle--tablet-p6") }}</p>
            </div>
        </section>
    </aside>
</template>

<script>
/* eslint-disable no-unused-vars */
// Spanish
import statsJson from '../../../assets/lottieAnimations/stats.json'
import reporteJson from '../../../assets/lottieAnimations/Reporte.json'

// English
import statsJsonEn from '../../../assets/lottieAnimations/en/stats.json'
import reporteJsonEn from '../../../assets/lottieAnimations/en/Reporte.json'

// English
import reporteJsonPt from '../../../assets/lottieAnimations/pt/Reporte.json'

export default {
    name: 'TwoCard',
    data() {
        return {
            statsJson,
            reporteJson,
            autoPlayStats: false,
            autoPlayReporte: false
        }
    },
    methods: {
        visibilityStatsChanged(isVisible, entry) {
            if(isVisible) {
                this.autoPlayStats = true
            }
        },
        visibilityReporteChanged(isVisible, entry) {
            if(isVisible) {
                this.autoPlayReporte = true
            }
        }
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if (newVal === 'pt') {
                this.statsJson = statsJson
                this.reporteJson = reporteJsonPt
            } else if (newVal === 'en') {
                this.statsJson = statsJsonEn
                this.reporteJson = reporteJsonEn
            } else {
                this.statsJson = statsJson
                this.reporteJson = reporteJson
            }
        }
    }
}
</script>

<style>
.two-card--section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;
}
.card--container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.inner-card {
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 630px;
    border-radius: 25px;
    padding: 60px;
}
.inner-card:hover {
    box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.2);
}
.s2--show-display {
    display: none;
}

@media (max-width: 1280px) {
    .inner-card {
        width: 335px;
    }
    .two-card--section {
        gap: 15px;
    }
    .card--container {
        gap: 10px;
    }
    .s2--show-display {
        display: none;
    }
}
@media (max-width: 1010px) {
    .two-card--section {
        gap: 8px;
    }
    .s2--two-display {
        display: none;
    }
    .s2--show-display {
        display: inline;
    }
    .inner-card {
        width: 210px;
    }
}
@media (max-width: 800px) {
    .two-card--section {
        gap: 4px;
    }
    .inner-card {
        width: 115px;
    }
}
</style>