<template>
    <aside class="container-inner-s4">
        <h2 v-if="this.index === 3" class="text-header-card-s4--styled ---force--line-height">Orkestra <br><span class="card-s4--resized-text-card">Automation & Bots</span></h2>
        <h2 v-else-if="this.index === 2" class="text-header-card-s4--styled">Orkestra <br>3PL</h2>
        <h2 v-else-if="this.index === 1" class="text-header-card-s4--styled">Last Mile <br>Software</h2>
        <h2 v-else-if="this.index === 0" class="text-header-card-s4--styled">Route <br>Planner</h2>
        <h2 v-else class="text-header-card-s4--styled">{{ title }}</h2>
        <Vue3Lottie :key="autoPlayRoute" v-observe-visibility="visibilityRouteChanged" class="cursor--pointer" v-if="this.index===0" :loop="false" :animationData="routeJson" :autoPlay="autoPlayRoute" />
        <Vue3Lottie :key="autoPlayLastMile" v-observe-visibility="visibilityLastMileChanged" class="cursor--pointer height--last-mile-lottie" v-else-if="this.index===1" :loop="false" :animationData="lastMileJson" :autoPlay="autoPlayLastMile" />
        <Vue3Lottie :key="autoPlayThreePl" v-observe-visibility="visibilityThreeChanged" class="cursor--pointer height--threepl-lottie" v-else-if="this.index===2" :loop="false" :animationData="threeplJson" :autoPlay="autoPlayThreePl" />
        <Vue3Lottie :key="autoPlayAutomation" v-observe-visibility="visibilityAutomationChanged" class="cursor--pointer height--automation-lottie" v-else-if="this.index===3" :loop="false" :animationData="automationJson" :autoPlay="autoPlayAutomation" />
        <aside class="grid---container-info-cl-txt">
            <div class="subtitles-styled--container">
                <p class="text-s4--inner p-s4--margin" v-for="(item, index) in subtitles" :key="index">{{ item }}</p>
            </div>
            <GreenButton />
        </aside>
    </aside>
</template>

<script>
/* eslint-disable no-unused-vars */ 
import GreenButton from './GreenButton.vue'

import threeplJson from '../../../assets/lottieAnimations/3pl.json'
import routeJson from '../../../assets/lottieAnimations/Route.json'
import lastMileJson from '../../../assets/lottieAnimations/LastMileIcon.json'
import automationJson from '../../../assets/lottieAnimations/Automation2.json'

export default {
    name: 'ColumnText',
    components: {
        GreenButton
    },
    data() {
        return {
            threeplJson,
            routeJson,
            lastMileJson,
            automationJson,
            autoPlayRoute: false,
            autoPlayLastMile: false,
            autoPlayThreePl: false,
            autoPlayAutomation: false
        }
    },
    methods: {
        visibilityRouteChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayRoute = true
            }
        },
        visibilityLastMileChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayLastMile = true
            }
        },
        visibilityThreeChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayThreePl = true
            }
        },
        visibilityAutomationChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayAutomation = true
            }
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            required: true
        },
        subtitles: {
            type: Array,
            required: true
        }
    }
}
</script>

<style>
.height--last-mile-lottie {
    height: 121px !important;
}
.height--threepl-lottie {
    height: 183px !important;
}
.height--automation-lottie {
    height: 180px !important;
}
.grid---container-info-cl-txt {
    display: grid;
    grid-template-rows: 190px 1fr;
}
.container-inner-s4 {
    display: grid;
    grid-template-rows: 100px 180px 230px;
    align-items: center;
}
.position-s4--absolute {
    position: absolute;
}
.subtitles-styled--container {
    display: flex;
    flex-direction: column;
}
</style>
