<template>
    <header class="div--position grid--style header--padding">
        <a href="/" class="--width-resized">
            <img class="padding-s1-logo--resized" alt="Logo Orketra" src="../../../assets/Logo.png" />
        </a>
        <div class="utils-s1--tablet header-options" :style="cssStyled">
            <a :href="`/#section${key}`" :class="`text--style ${selected === option ? '--underline' : ''}`" v-for="(option, key) in options" v-bind:key="key" v-on:click="changeSelected(key, true)">
                {{ option }}
            </a>
            <div v-click-outside="onClickOutside" class="dropdown cursor--pointer text--style">
                <div id="modal-lang-2" class="container--image-option ---force-join-flag" v-on:click="openCloseDropdown">
                    <img v-if="lang==='Español'" alt="espanol" class="resize--flag" src="../../../assets/es.png" />
                    <img v-else-if="lang==='Ingles'" alt="espanol" class="resize--flag" src="../../../assets/en.png" />
                    <img v-else alt="espanol" class="resize--flag--br" src="../../../assets/br.png" />
                </div>
                <div id="modal-lang" v-if="$store.state.isChangingLang" class="dropdown-content">
                    <div v-if="lang!=='Español'" class="container--image-option" v-on:click="changeLang('es')">
                        <img alt="espanol" class="resize--flag" src="../../../assets/es.png" />
                    </div>
                    <div v-if="lang!=='Ingles'" class="container--image-option" v-on:click="changeLang('en')">
                        <img alt="espanol" class="resize--flag" src="../../../assets/en.png" />
                    </div>
                    <div v-if="lang!=='Portugues'" class="container--image-option" v-on:click="changeLang('pt')">
                        <img alt="espanol" class="resize--flag--br" src="../../../assets/br.png" />
                    </div>
                </div>
            </div>
        </div> 
        <GeneralButton v-on:click="sendEmail" class="utils-s1--tablet" :color="color" :textColor="textColor" :text="btnName"></GeneralButton>
        <img class="utils-desktop --margin-bottom" alt="options--button" src="../../../assets/s1-three-lines-tablet.png" v-on:click="changeShowModal" />
        <aside v-if="showModal" class="menu--header-styled">
            <div class="logo--header-option-container">
                <img class="padding-s1-logo--resized" alt="Logo Orkestra Mobile" src="../../../assets/Logo.png" />
                <img class="padding-s1-logo--resized" alt="Logo Remove Mobile" src="../../../assets/headerRemove.png" v-on:click="changeShowModal" />
            </div>
            <div class="container---logo">
                <a :href="`#section${key}`" :class="`text--style ${selected === option ? '--underline' : ''}`" v-for="(option, key) in options" v-bind:key="key" v-on:click="changeSelected(key, false)">
                    {{ option }}
                </a>
                <div class="dropdown cursor--pointer text--style">
                    <div class="container--image-option ---force-join-flag" v-on:click="openCloseDropdownMobile">
                        <img v-if="lang==='Español'" alt="espanol" class="resize--flag" src="../../../assets/es.png" />
                        <img v-else-if="lang==='Ingles'" alt="espanol" class="resize--flag" src="../../../assets/en.png" />
                        <img v-else alt="espanol" class="resize--flag--br" src="../../../assets/br.png" />
                    </div>
                    <div v-if="isDropdownOpenMobile" class="dropdown-content">
                        <div v-if="lang!=='Español'" class="container--image-option" v-on:click="changeLang('es')">
                            <img alt="espanol" class="resize--flag" src="../../../assets/es.png" />
                        </div>
                        <div v-if="lang!=='Ingles'" class="container--image-option" v-on:click="changeLang('en')">
                            <img alt="espanol" class="resize--flag" src="../../../assets/en.png" />
                        </div>
                        <div v-if="lang!=='Portugues'" class="container--image-option" v-on:click="changeLang('pt')">
                            <img alt="espanol" class="resize--flag--br" src="../../../assets/br.png" />
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    </header>
</template>

<script>
/* eslint-disable no-unused-vars */
import vClickOutside from 'click-outside-vue3'
import GeneralButton from '../../generalComponents/Button.vue'

export default {
    data() {
        return {
            selected: null,
            force: 1,
            options: [
                this.$t("message.header-features"),
                this.$t("message.header-solutions"),
                this.$t("message.header-impact"),
                this.$t("message.header-cases"),
                this.$t("message.header-clients"),
                // this.$t("message.header-the-team"),
                this.$t("message.header-industries")
            ],
            langs: ['es', 'pt', 'en'],
            lang: this.parserLang(this.$i18n.locale),
            btnName: this.$t("message.header-contact-btn"),
            color: '#f4fa73',
            textColor: '#000000',
            thickness: '0em',
            decoration: 'none',
            showModal: false,
            isDropdownOpenMobile: false
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    components: {
        GeneralButton
    },
    methods: {
        onClickOutside (event) {
            if (this.$store.state.isChangingLang) {
                this.$store.commit('forceValueLang', false)
            }
        },
        changeLang(locale) {
            this.openCloseDropdown()
            this.lang = this.parserLang(locale)
            this.$root.$i18n.locale = locale
        },
        parserLang(newLang) {
            if (newLang === 'es') {
                return 'Español'
            } else if (newLang === 'pt') {
                return 'Portugues'
            } else {
                return 'Ingles'
            }
        },
        changeSelected(key, isDesktop) {
            this.selected = this.options[key]

            if (!isDesktop) {
                this.changeShowModal()
            }
        },
        openCloseDropdown() {
            this.$store.commit('openCloseLanOptions')
        },
        openCloseDropdownMobile() {
            this.isDropdownOpenMobile = !this.isDropdownOpenMobile
        },
        sendEmail() {
            window.open(`mailto:orkestra@shippify.co`, '__self')
        },
        changeShowModal() {
            this.showModal = !this.showModal
        }
    },
    computed: {
        cssStyled() {
            return {
                '--thick-size': this.thickness,
                '--decoration': this.decoration
            }
        }
    },
    watch: {
        lang: function(old, newValue) {
            this.$store.commit('forceValueLang', false)
            if (this.isDropdownOpenMobile) {
                this.isDropdownOpenMobile = false
            }
            this.options = [
                this.$t("message.header-features"),
                this.$t("message.header-solutions"),
                this.$t("message.header-impact"),
                this.$t("message.header-cases"),
                this.$t("message.header-clients"),
                // this.$t("message.header-the-team"),
                this.$t("message.header-industries")
            ]
            this.btnName = this.$t("message.header-contact-btn")
        }
    },
    mounted() {
        var className = "bg--active-header";
        var scrollTrigger = 60;

        // Check if the header needs to change
        window.onscroll = () => {
            if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
                document.getElementsByTagName("header")[0].classList.add(className);
                this.color = '#000000'
                this.textColor = '#f4fa73'
                this.thickness = '0.2em'
                this.decoration = 'underline'
            } else {
                document.getElementsByTagName("header")[0].classList.remove(className);
                this.color = '#f4fa73'
                this.textColor = '#000000'
                this.thickness = '0em'
                this.decoration = 'none'
                this.selected = null
            }
        }
    }
}
</script>

<style>
.resize--flag {
    height: 32px;
    width: 32px;
    border-radius: 2.5em;
}
.resize--flag--br {
    height: 30px;
    width: 32px;
    border-radius: 2.5em;
}
.container--image-option {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 3px;
    align-items: center;
    align-content: space-between;
}
.---force-join-flag {
    grid-template-columns: 70px 1fr;
}
.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}
.dropdown-content {
  position: absolute;
  min-width: 40px;
  padding: 12px 0px;
  text-align: left;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.--autoalign-select {
    align-self: flex-start;
}
option {
    text-decoration: none;
    font-size: 12pt;
    cursor: pointer;
    font-family: 'BrotherMedium', Courier, monospace;
    white-space: nowrap;
    color: black
}
a {
    text-decoration: none;
    font-size: 12pt;
    cursor: pointer;
    font-family: 'BrotherMedium', Courier, monospace;
    white-space: nowrap;
    color: black
}

.--width-resized {
    width: 180px;
}
.container---logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 50px;
}
.--margin-bottom {
    margin-bottom: 10px;
}
.logo--header-option-container {
    display: grid;
    grid-template-columns: 1fr 21px;
    align-items: center;
}
.--header-alig-center {
    text-align: center;
}
.menu--header-styled {
    background-color: white;
    position: absolute;
    z-index: 50;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 43px 46px;
}
.div--position {
    position: absolute;
    z-index: 10;
    top: 0px;
}
.header--padding {
    padding: 0px 8%;
    width: 100vw;
}
.--underline {
    text-decoration: var(--decoration);
    text-decoration-thickness: var(--thick-size);
}
.grid--style {
    display: grid;
    gap: 20px;
    align-items: center;
    position: fixed;
    grid-template-columns: 350px 1fr 150px;
}
.bg--active-header {
    background-color: #f4fa73;
}
.padding-s1-logo--resized {
    padding: .5rem 0rem;
}
.header-options {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
}
@media (max-width: 1260px) {
    .grid--style {
        grid-template-columns: 200px 1fr 150px;
    }
}
@media (min-width: 1011px) {
    .utils-desktop {
        display: none;
    }
}
@media (max-width: 1010px) {
    .utils-s1--tablet {
        display: none;
    }
    .header--padding {
        padding: 43px 66px;
    }
    .grid--style {
        display: grid;
        grid-template-columns: 70vw 20px;
        align-content: center;
    }
}
@media (max-width: 800px) {
    .header--padding {
        padding: 30px 33px;
    }
    a {
        font-size: 20pt;
        text-align: start;
    }
}
@media (max-width: 680px) {
    .header--padding {
        padding: 23px 23px;
    }
}
</style>
