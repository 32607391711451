import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'

import Vue3Lottie from 'vue3-lottie'
import VueObserveVisibility from 'vue-observe-visibility'


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Firebase
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkk3FGg-hyfGvDScnloZhYVLv3SSvmpBI",
  authDomain: "dev-orkestra.firebaseapp.com",
  projectId: "dev-orkestra",
  storageBucket: "dev-orkestra.appspot.com",
  messagingSenderId: "32557858227",
  appId: "1:32557858227:web:a64dae7a86c0e472a75fc6"
};
// Vuetify config
const vuetify = createVuetify({
  components,
  directives,
})

// Initialize Firebase and App
initializeApp(firebaseConfig);
createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueObserveVisibility)
  .use(Vue3Lottie)
  .use(vuetify)
  .mount('#app')
