import { createI18n } from 'vue-i18n'

const messages = {
  es: {
    message: {
      "header-features": "Features",
      "header-solutions": "Soluciones",
      "header-cases": "Casos",
      "header-clients": "Clientes",
      "header-the-team": "The Team",
      "header-industries": "Industrias",
      "header-impact": "Impacto",
      "header-contact-btn": "CONTÁCTANOS",
      "s1--button-start": 'Empieza',
      "s1--title--button1": "Tu logística como una",
      "s1--title--button2": "sinfonía",
      "s2-title": "Integra toda tu logística",
      "s2-title-mobile-p1": "Integra toda",
      "s2-title-mobile-p2": "tu logística",
      "s2-subtitle--p1": "Orkestra es la aplicación que",
      "s2-subtitle--desktop-p2": "unifica, coordina,",
      "s2-subtitle--desktop-p3": "digitaliza y evalúa",
      "s2-subtitle--desktop-p4": "tu flota.",
      "s2-subtitle--mobile-p2": "unifica,",
      "s2-subtitle--mobile-p3": "coordina,digitaliza y evalúa",
      "s2-card1-title--p1": "Rutas óptimizadas &",
      "s2-card1-title--p2": "Mapa de Calor",
      "s2-card1-subtitle--p1": "El sistema crea la ruta más óptima",
      "s2-card1-subtitle--p2": "priorizando la distancia según las",
      "s2-card1-subtitle--p3": "variables asignadas",
      "s2-card1-option1--p1": "Evidencia zonas con mayor densidad",
      "s2-card1-option1--p2": "de entregas.",
      "s2-card1-option2": "Planificación 100% efectiva.",
      "s2-card2-title": "Estadística Personalizada",
      "s2-card2-subtitle--desktop-p1": "Crea tus propios gráficos con más de 50 KPIs",
      "s2-card2-subtitle--desktop-p2": "personalizables y Agrupa tus gráficos por tableros.",
      "s2-card2-subtitle--desktop-p3": "Mantén tu data ordenada.",
      "s2-card2-subtitle--tablet-p1": "Crea tus propios gráficos con",
      "s2-card2-subtitle--tablet-p2": "más de 50 KPIs personalizables y",
      "s2-card2-subtitle--tablet-p3": "Agrupa tus gráficos",
      "s2-card2-subtitle--tablet-p4": "por tableros.",
      "s2-card2-subtitle--tablet-p5": "Mantén tu data ordenada.",
      "s2-card2-subtitle--mobile-p1": "Crea tus propios gráficos con más de 50 KPIs",
      "s2-card2-subtitle--mobile-p2": "personalizables y Agrupa tus gráficos",
      "s2-card2-subtitle--mobile-p3": "por tableros. Mantén tu data ordenada.",
      "s2-card3-title": "Reporte personalizado",
      "s2-card3-subtitle--desktop-p1": "Orkestra cuenta con 4 tipos de reportes por defecto:",
      "s2-card3-subtitle--desktop-p2": "entregas, rutas, eventos e incidencias.",
      "s2-card3-subtitle--desktop-p3": "Puedes agregar siempre más data si lo requieres.",
      "s2-card3-subtitle--tablet-p1": "Orkestra cuenta con 4 tipos",
      "s2-card3-subtitle--tablet-p2": "de reportes por defecto:",
      "s2-card3-subtitle--tablet-p3": "entregas, rutas, eventos",
      "s2-card3-subtitle--tablet-p4": "e incidencias.",
      "s2-card3-subtitle--tablet-p5": "Puedes agregar siempre más",
      "s2-card3-subtitle--tablet-p6": "data si lo requieres.",
      "s2-card3-subtitle--mobile-p1": "Orkestra cuenta con 4 tipos de reportes",
      "s2-card3-subtitle--mobile-p2": "por defecto: entregas, rutas, eventos",
      "s2-card3-subtitle--mobile-p3": "e incidencias.Puedes agregar",
      "s2-card3-subtitle--mobile-p4": "siempre más data si lo requieres.",
      "s2-card4-title": "Añade tu marca",
      "s2-card4-subtitle--p1": "La plataforma puede ser completamente",
      "s2-card4-subtitle--p2": "brandeada con los colores y logo de tu",
      "s2-card4-subtitle--p3": "empresa.",
      "s2-card4-list-option1": "Tu marca en notificaciones a clientes",
      "s2-card4-list-option2": "Presencia corporativa en todo momento",
      "s2-card5-title": "Agrega subcuentas",
      "s2-card5--subtitle-p1": "Subcuentas asociadas a una cuenta master",
      "s2-card5--subtitle-p2": "Permisos de ciudad, Reportes generales",
      "s3-title--desktop-p1": "Orquesta de forma",
      "s3-title--desktop-p2": "estratégica y eficiente",
      "s3-title--mobile-p1": "Orquesta de",
      "s3-title--mobile-p2": "forma estratégica",
      "s3-title--mobile-p3": "y eficiente",
      "s4-title--desktop-p1": "Varias soluciones para tu",
      "s4-title--desktop-p2": "logística maestra",
      "s4-title--mobile-p1": "Varias soluciones",
      "s4-title--mobile-p2": "para tu logística",
      "s4-title--mobile-p3": "maestra",
      "s4-column-text--p1": "Planificación",
      "s4-column-text--p2": "Geocercas",
      "s4-column-text--p3": "Planificación Automática",
      "s4-column-text--p4": "Visualización de rutas",
      "s4-column-text--p5": "Control de recursos",
      "s4-column-text--p6": "Historial de entregas",
      "s4-column-text--p7": "Tracking personalizado",
      "s4-column-text--p8": "Alertas",
      "s4-column-text--p9": "Estadísticas",
      "s4-column-text--p10": "Control logística",
      "s4-column-text--p11": "Integraciones",
      "s4-column-text--p12": "Omnicanalidad",
      "s5-title--p1": "Una sinfonía",
      "s5-title--p2": "logística",
      "s5-subtitle--p1": "¿Qué esperas para orquestar",
      "s5-subtitle--p2": " toda tu operación?",
      "s6-title--p1": "Experiencia",
      "s6-title--p2": "orkestra",
      "s6-subtitle-p1": "Tú tienes el mejor producto,",
      "s6-subtitle-p2": "nosotros agregamos una",
      "s6-subtitle-p3": "gran experiencia",
      "s8-title--desktop-p1": "Logística eficiente en",
      "s8-title--desktop-p2": "cada industria",
      "s8-title--mobile-p1": "Logística",
      "s8-title--mobile-p2": "eficiente en cada",
      "s8-title--mobile-p3": "industria",
      "s9-title1": "PRODUCTOS",
      "s9-title2": "RECURSOS",
      "s9-title3": "COMPAÑÍA",
      "s9-subtitle1": "Casos de éxito",
      "s9-subtitle2": "Sobre Nosotros",
      "s9-subtitle3": "Trabaja con nosotros",
      "s9-subtitle4": "Seguridad",
      "s9-subtitle5": "Política de Privacidad",
      "s9-subtitle6": "Términos y Condiciones",
      "notFound--p1": 'Ops!',
      "notFound--p2": "Estamos en",
      "notFound--p3": "construccion,",
      "notFound--p4": "grandes",
      "notFound--p5": "cosas, estan por venir!",
    }
  },
  en: {
    message: {
      "header-features": "Features",
      "header-solutions": "Solutions",
      "header-cases": "Cases",
      "header-clients": "Clients",
      "header-the-team": "The Team",
      "header-industries": "Industries",
      "header-impact": "Impact",
      "header-contact-btn": "CONTACT",
      "s1--button-start": 'Company',
      "s1--title--button1": "Your logistic like a",
      "s1--title--button2": "symphony",
      "s2-title": "Integrate all your logistic",
      "s2-title-mobile-p1": "Integrate all",
      "s2-title-mobile-p2": "your logistic",
      "s2-subtitle--p1": "Orkestra is the application that",
      "s2-subtitle--desktop-p2": "unifies, coordinates,",
      "s2-subtitle--desktop-p3": "digitizes and evaluates",
      "s2-subtitle--desktop-p4": "your fleet.",
      "s2-subtitle--mobile-p2": "unifies,",
      "s2-subtitle--mobile-p3": "coordinates,digitizes and evaluates",
      "s2-card1-title--p1": "Optimized Routes and",
      "s2-card1-title--p2": "Heat Map",
      "s2-card1-subtitle--p1": "The system creates the ideal route",
      "s2-card1-subtitle--p2": "prioritizing the distance according to",
      "s2-card1-subtitle--p3": "the assigned variables",
      "s2-card1-option1--p1": "Shows areas with higher density",
      "s2-card1-option1--p2": "of deliveries.",
      "s2-card1-option2": "100% effective planning.",
      "s2-card2-title": "Custom Stats",
      "s2-card2-subtitle--desktop-p1": "Create your own charts with 50+ customizable KPIs",
      "s2-card2-subtitle--desktop-p2": "and group your graphics by frames.",
      "s2-card2-subtitle--desktop-p3": "Keep your data organized.",
      "s2-card2-subtitle--tablet-p1": "Create your own charts with",
      "s2-card2-subtitle--tablet-p2": "50+ customizable KPIs and",
      "s2-card2-subtitle--tablet-p3": "group your graphics",
      "s2-card2-subtitle--tablet-p4": "by frames.",
      "s2-card2-subtitle--tablet-p5": "Keep your data organized.",
      "s2-card2-subtitle--mobile-p1": "Create your own charts with 50+ customizable KPIs",
      "s2-card2-subtitle--mobile-p2": "and group your graphics",
      "s2-card2-subtitle--mobile-p3": "by frames. Keep your data organized.",
      "s2-card3-title": "Custom Report",
      "s2-card3-subtitle--desktop-p1": "Orkestra has 4 types of reports by default:",
      "s2-card3-subtitle--desktop-p2": "Deliveries, Routes, Events and Incidents.",
      "s2-card3-subtitle--desktop-p3": "You can always add more data if you need to.",
      "s2-card3-subtitle--tablet-p1": "Orkestra has 4 types",
      "s2-card3-subtitle--tablet-p2": "of reports by default:",
      "s2-card3-subtitle--tablet-p3": "deliveries, routes, events",
      "s2-card3-subtitle--tablet-p4": "and incidents.",
      "s2-card3-subtitle--tablet-p5": "You can always add more",
      "s2-card3-subtitle--tablet-p6": "data if you need to.",
      "s2-card3-subtitle--mobile-p1": "Orkestra has 4 types of reports",
      "s2-card3-subtitle--mobile-p2": "by default: deliveries, routes, events",
      "s2-card3-subtitle--mobile-p3": "e incidents.You can always",
      "s2-card3-subtitle--mobile-p4": "add more data if you need to.",
      "s2-card4-title": "Put your brand",
      "s2-card4-subtitle--p1": "The platform can be fully",
      "s2-card4-subtitle--p2": "personalized with your business colors, logo and",
      "s2-card4-subtitle--p3": "features.",
      "s2-card4-list-option1": "Your brand in customer notifications",
      "s2-card4-list-option2": "Corporate presence at all times",
      "s2-card5-title": "Add sub-accounts",
      "s2-card5--subtitle-p1": "Sub-accounts associated with a Master account",
      "s2-card5--subtitle-p2": "allow you to create general reports",
      "s3-title--desktop-p1": "Orchestrate strategically",
      "s3-title--desktop-p2": "and efficiently",
      "s3-title--mobile-p1": "Orchestrate",
      "s3-title--mobile-p2": "strategically",
      "s3-title--mobile-p3": "and efficiently",
      "s4-title--desktop-p1": "Diverse solutions for",
      "s4-title--desktop-p2": "your master logistics",
      "s4-title--mobile-p1": "Diverse solutions",
      "s4-title--mobile-p2": "for your master",
      "s4-title--mobile-p3": "logistics",
      "s4-column-text--p1": "Planning",
      "s4-column-text--p2": "Geofences",
      "s4-column-text--p3": "Automatic Planning",
      "s4-column-text--p4": "Route View",
      "s4-column-text--p5": "Resource control",
      "s4-column-text--p6": "Delivery history",
      "s4-column-text--p7": "Custom tracking",
      "s4-column-text--p8": "Alerts",
      "s4-column-text--p9": "Statistics",
      "s4-column-text--p10": "Automatic communication",
      "s4-column-text--p11": "Integrations",
      "s4-column-text--p12": "Omnichannel",
      "s5-title--p1": "A Logistical",
      "s5-title--p2": "Symphony",
      "s5-subtitle--p1": "What are you waiting for to ",
      "s5-subtitle--p2": "orchestrate your entire operation?",
      "s6-title--p1": "Orkestra",
      "s6-title--p2": "experience",
      "s6-subtitle-p1": "You have the best product,",
      "s6-subtitle-p2": "we complement it with",
      "s6-subtitle-p3": "a great experience.",
      "s8-title--desktop-p1": "Efficient logistics in",
      "s8-title--desktop-p2": "each industry",
      "s8-title--mobile-p1": "Efficient",
      "s8-title--mobile-p2": "logistic in each",
      "s8-title--mobile-p3": "industry",
      "s9-title1": "PRODUCTS",
      "s9-title2": "RESOURCES",
      "s9-title3": "COMPANY",
      "s9-subtitle1": "Success stories",
      "s9-subtitle2": "About Us",
      "s9-subtitle3": "Work with us",
      "s9-subtitle4": "Security",
      "s9-subtitle5": "Privacy Policy",
      "s9-subtitle6": "Terms and Conditions",
      "notFound--p1": 'Oops!',
      "notFound--p2": "We are",
      "notFound--p3": "construction,",
      "notFound--p4": "great",
      "notFound--p5": "things, are coming!",
    }
  },
  pt: {
    message: {
      "header-features": "Features",
      "header-solutions": "Soluções",
      "header-cases": "Casos",
      "header-clients": "Clientes",
      "header-the-team": "O Time",
      "header-industries": "Industrias",
      "header-impact": "Impacto",
      "header-contact-btn": "CONTATO",
      "s1--button-start": 'Empresa',
      "s1--title--button1": "Sua logística como uma",
      "s1--title--button2": "sinfonia",
      "s2-title": "Integre toda a sua logística",
      "s2-title-mobile-p1": "Integre toda a",
      "s2-title-mobile-p2": "sua logística",
      "s2-subtitle--p1": "Orkestra é o aplicativo que",
      "s2-subtitle--desktop-p2": "unifica, coordena,",
      "s2-subtitle--desktop-p3": "digitaliza e avalia",
      "s2-subtitle--desktop-p4": "sua frota.",
      "s2-subtitle--mobile-p2": "unifica,",
      "s2-subtitle--mobile-p3": "coordena,digitaliza e avalia",
      "s2-card1-title--p1": "Rotas Otimizadas e",
      "s2-card1-title--p2": "Mapa de Calor",
      "s2-card1-subtitle--p1": "O sistema cria a rota ideal",
      "s2-card1-subtitle--p2": "priorizando a distância de acordo",
      "s2-card1-subtitle--p3": "com o variáveis ​atribuídas",
      "s2-card1-option1--p1": "Mostra áreas com maior densidade",
      "s2-card1-option1--p2": "de entregas.",
      "s2-card1-option2": "Planejamento 100% eficaz.",
      "s2-card2-title": "Estatística personalizada",
      "s2-card2-subtitle--desktop-p1": "Crie seus próprios gráficos com mais de 50 KPIs",
      "s2-card2-subtitle--desktop-p2": "personalizáveis ​e agrupe seus gráficos por quadros.",
      "s2-card2-subtitle--desktop-p3": "Mantenha seus dados organizados.",
      "s2-card2-subtitle--tablet-p1": "Crie seus próprios gráficos com",
      "s2-card2-subtitle--tablet-p2": "mais de 50 KPIs personalizáveis e",
      "s2-card2-subtitle--tablet-p3": "agrupe seus gráficos",
      "s2-card2-subtitle--tablet-p4": "por quadros.",
      "s2-card2-subtitle--tablet-p5": "Mantenha seus dados organizados.",
      "s2-card2-subtitle--mobile-p1": "Crie seus próprios gráficos com mais de 50 KPIs",
      "s2-card2-subtitle--mobile-p2": "personalizáveis ​e agrupe seus gráficos",
      "s2-card2-subtitle--mobile-p3": "por quadros. Mantenha seus dados organizados.",
      "s2-card3-title": "Relatório personalizado",
      "s2-card3-subtitle--desktop-p1": "Orkestra tem 4 tipos de relatórios por padrão:",
      "s2-card3-subtitle--desktop-p2": "Entregas, Rotas, Eventos e Incidentes.",
      "s2-card3-subtitle--desktop-p3": "Você sempre pode adicionar mais dados se precisar.",
      "s2-card3-subtitle--tablet-p1": "Orkestra tem 4 tipos",
      "s2-card3-subtitle--tablet-p2": "de relatórios por padrão:",
      "s2-card3-subtitle--tablet-p3": "entregas, rotas, eventos",
      "s2-card3-subtitle--tablet-p4": "e incidentes.",
      "s2-card3-subtitle--tablet-p5": "Você sempre pode adicionar mais",
      "s2-card3-subtitle--tablet-p6": "dados se precisar.",
      "s2-card3-subtitle--mobile-p1": "Orkestra tem 4 tipos de relatórios",
      "s2-card3-subtitle--mobile-p2": "por padrão: entregas, rotas, eventos",
      "s2-card3-subtitle--mobile-p3": "e incidentes.Você sempre pode",
      "s2-card3-subtitle--mobile-p4": "adicionar mais dados se precisar.",
      "s2-card4-title": "Deixe a sua marca",
      "s2-card4-subtitle--p1": "A plataforma pode ser totalmente",
      "s2-card4-subtitle--p2": "personalizada com as cores, logo e recursos do seu",
      "s2-card4-subtitle--p3": "negócio.",
      "s2-card4-list-option1": "Sua marca nas notificações dos clientes",
      "s2-card4-list-option2": "Presença corporativa em todos os momentos",
      "s2-card5-title": "Adicionar subcontas",
      "s2-card5--subtitle-p1": "As subcontas associadas a uma Conta Mestra",
      "s2-card5--subtitle-p2": "permitem criar relatórios gerais",
      "s3-title--desktop-p1": "Orquestre de forma",
      "s3-title--desktop-p2": "estratégica e eficiente",
      "s3-title--mobile-p1": "Orquestre de",
      "s3-title--mobile-p2": "forma estratégica",
      "s3-title--mobile-p3": "e eficiente",
      "s4-title--desktop-p1": "Diversas soluções para",
      "s4-title--desktop-p2": "sua logística mestre",
      "s4-title--mobile-p1": "Diversas soluções",
      "s4-title--mobile-p2": "para sua logística",
      "s4-title--mobile-p3": "mestre",
      "s4-column-text--p1": "Planificação",
      "s4-column-text--p2": "Geocercas",
      "s4-column-text--p3": "Planificação Automática",
      "s4-column-text--p4": "Visualização de Rotas",
      "s4-column-text--p5": "Controle de recursos",
      "s4-column-text--p6": "Histórico de entrega",
      "s4-column-text--p7": "Rastreamento personalizado",
      "s4-column-text--p8": "Alertas",
      "s4-column-text--p9": "Estatísticas",
      "s4-column-text--p10": "Comunicação automática",
      "s4-column-text--p11": "Integrações",
      "s4-column-text--p12": "Omnicanalidade",
      "s5-title--p1": "Uma sinfonia",
      "s5-title--p2": "logística",
      "s5-subtitle--p1": "O que você está esperando para ",
      "s5-subtitle--p2": " orquestrar toda a sua operação?",
      "s6-title--p1": "Experiência",
      "s6-title--p2": "Orkestra",
      "s6-subtitle-p1": "Você tem o melhor produto,",
      "s6-subtitle-p2": "nós o complementamos com",
      "s6-subtitle-p3": "uma ótima experiência.",
      "s8-title--desktop-p1": "Logística eficiente",
      "s8-title--desktop-p2": "em cada insústria",
      "s8-title--mobile-p1": "Logística",
      "s8-title--mobile-p2": "eficiente em cada",
      "s8-title--mobile-p3": "insústria",
      "s9-title1": "PRODUTOS",
      "s9-title2": "RECURSOS",
      "s9-title3": "EMPRESA",
      "s9-subtitle1": "Massa ac quam",
      "s9-subtitle2": "Amet eget faucibus",
      "s9-subtitle3": "Imperdiet nunc eu",
      "s9-subtitle4": "Segurança",
      "s9-subtitle5": "Política de privacidade",
      "s9-subtitle6": "Termos e Condições",
      "notFound--p1": 'Ops!',
      "notFound--p2": "Estamos em",
      "notFound--p3": "construção,",
      "notFound--p4": "grandes",
      "notFound--p5": "coisas, estão por vir",
    }
  }
}

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages
})
