<template>
  <HeaderOptions></HeaderOptions>
  <section class="shy-policy-privacy">
    <h2 class="text-header--styled --reduce-line-height-s5 utils-s1--tablet">
      Privacy Policy
    </h2>
    <h2 class="text-header--styled --reduce-line-height-bigger utils-desktop">
      Privacy Policy
    </h2>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Introduction"
      ></HeaderWithCicle>
      <p>
        Orkestra Inc. (“Orkestra”) understands and respects our users’ need for
        privacy. This Privacy Notice (“Notice”) describes the types of
        information we collect, the purposes for which it is used, and the
        choices you have with respect to its use.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="About Orkestra"
      ></HeaderWithCicle>
      <p>
        Orkestra is a logistics management software and route optimization
        platform for businesses offering last-mile delivery. You can access the
        Orkestra Portal (“Platform” or “Platforms”) via our website dashboard,
        smartphone applications, SMS messaging feature on mobile devices, APIs,
        and through third-parties. For more information about our Platform,
        check out our “Features” section on our website.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Scope and Application"
      ></HeaderWithCicle>
      <p>
        This Notice applies to users of the Orkestra App (e.g. delivery drivers
        or “Drivers”), paying Orkestra clients (“Clients”), and Orkestra website
        visitors (including our homepage at Orkestra.co, and our blog, order,
        reseller, and support pages, collectively “Sites”). This Notice also
        applies to our Clients’ customers (“Recipients”). We encourage you to
        read this Notice in full to understand our privacy practices before
        using our Platform.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Information Orkestra Receives From You"
      ></HeaderWithCicle>
      <p>
        Your personal information helps us connect drivers, find delivery routes
        close to you, ensure customer support is available when needed, and
        provide other aspects of the Orkestra platform. We collect the following
        information through your use of our Platform, and otherwise with your
        consent. In some cases, we receive information directly from you, such
        as when you sign up for a dispatcher or administrative Orkestra account,
        we collect your name, email address, and phone number. In other cases,
        we receive information about you from our Clients when they use our
        application, such as your name and phone number when you are added as a
        driver by an administrator or dispatcher. Orkestra collects the
        following information:
      </p>
      <ol>
        <li>
          Information provided by users. This includes:
          <ul>
            <li>
              User profile information – We collect data when users create or
              update their Orkestra accounts. This may include Name, email
              address, postal address, phone number, emergency contact
              information, company name.
            </li>
            <li>
              Background check information (drivers) – This includes information
              submitted during the driver person application process, such as
              driver history or criminal record (where permitted by law),
              license status, known aliases and prior addresses.
            </li>
            <li>
              Vehicle information (drivers) – Transportation method, vehicle
              year, make, model, color, and license plate number. You provide
              this information to us directly when using our Platform.
            </li>
            <li>
              Identity verification photos (drivers) – This includes photos of
              users (such as selfies) and/or their government-issued
              identifications (such as driving licenses or identity documents).
              You provide this information to us directly when using our
              Platform.
            </li>
            <li>
              Business contact information – We collect personal information
              from individuals when we attend or host events, conferences, and
              other business meetings. This information includes business
              contact information such as name, email address, and phone number.
            </li>
          </ul>
        </li>

        <li>
          Data created during use of our services. This includes:
          <ul>
            <li>
              Delivery information – We collect information about customer and
              delivery information such as scheduling information, contact
              information, pick up information and delivery destination.
              Orkestra collects this information automatically when you use our
              Platform.
            </li>
            <li>
              Driver behavioral information (drivers) – We collect information
              about how you drive your vehicle. This includes time, location,
              bearing, speed, and accuracy. Orkestra collects this information
              automatically when you use our Platform.
            </li>
            <li>
              Geolocation information (drivers) – Driver location is collected
              from their device's native location service, which combines GPS,
              cell-tower, Wi-Fi, and other sensor data to accurately pinpoint a
              driver's latitude and longitude while the driver is "on-duty." We
              do not access, collect, or store location information from a
              driver's mobile device when they are off-duty or offline. We may
              collect other information such as application crash reports or
              application usage analytics for drivers who are not online for the
              express purpose of providing and improving our Platform. For
              example, if you engage the chat function as a driver while
              off-duty, we may collect and use application usage analytics for
              support purposes to understand the driver journey. We may remotely
              log in to the application, with your permission, to resolve an
              issue if our application crashes.
            </li>
            <li>
              Device information (drivers) – IP address, device type, operating
              system, manufacturer, model and version number, and unique device
              identifiers such as your device ID, but not IMEI. We collect this
              information automatically when you use our Platform.
            </li>
            <li>
              Browser and usage information – Operating System (OS) running on
              your device, Internet Protocol (IP) address, access times, browser
              type, and language, and the website you visited before our Sites.
              We also collect usage details, such as time, frequency, and use
              pattern. In some cases, we analyze information on specific end
              users such as the amount of time spent on the platform. In other
              cases, we look at data in the aggregate. We use this information
              to optimize our Platform. We collect this information
              automatically when you use our Platform.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        To learn about your information collection choices and to opt-out of
        data collection, see the “Your Choices” section below.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Information We Receive from Other Sources"
      ></HeaderWithCicle>
      <ol>
        <li>
          Clients of our Platform – Clients of our Services may provide
          information about you when they submit content through our Platform.
          For example, you may be mentioned in a troubleshooting ticket opened
          by someone else. We also receive your email address from other users
          when they provide it to invite you to our Platform. Similarly, a
          dispatcher may provide your contact information when they designate
          you as a driver on their company’s account.
        </li>
        <li>
          Client customers – We may receive personal data about you from one of
          our Client’s customers. We will only use this information for the
          purpose of providing our Platform. For example, a recipient of a
          delivery may provide personal information about you when submitting a
          support ticket.
        </li>
        <li>
          Other partners – We receive information from partners to help us find
          potential customers and enhance our Platform with useful information.
          For example, information provided by our partners allows us to provide
          route optimization services, such as when we receive mapping and
          location information or spatial data analytics from Google Maps.
        </li>
      </ol>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="How We Use, Share, and Disclose Information"
      ></HeaderWithCicle>
      <p>
        Orkestra does not sell your personal information. To make the Orkestra
        Platform work, we may need to share your personal information with other
        users, third parties, and service providers. This section explains when
        and why we use and share your information.
      </p>
      <p>We use collected information to:</p>
      <ol>
        <li>
          Communicate with you – We may contact you to respond to your
          inquiries, requests, and/or send important notices either via email,
          SMS, push notifications, and in-app notifications. This includes, for
          example, sending confirmations, invoices, technical notices, routing
          updates, security alerts, administrative messages, and providing you
          with updates about our Platform and new features. See “Your Choices”
          below to learn how to manage your communication preferences.
        </li>
        <li>
          Provide customer support - We use your personal and Orkestra usage
          information to resolve support issues that may arise through your use
          of our Orkestra app. Orkestra employees are prohibited from viewing
          the content of data you import into your Orkestra account, except when
          necessary to resolve your support issues. Access is limited to the
          data required to resolve your support issues.
        </li>
        <li>
          Deliver and improve our Platform - We use your information to deliver
          and analyze how you use our apps, develop new products and services,
          and improve functionality, quality, and user experience. For example,
          we track geolocation, driver behavior, and other information to
          facilitate route optimization and other features. This includes using
          aggregated, anonymized data to improve our Platform. For more
          information see “Browser and usage information” in the “Information We
          Receive From You” section above.
        </li>
        <li>
          Market our Platform - We may use your personal information for a
          business purpose to provide you with a variety of the Orkestra
          Platform’s features and services. For example, we may send you an
          email notification about new product features. Additionally, we may
          use pseudonymized personal information collected on our website to
          better tailor marketing or website content, including to run or
          optimize our service, or for other purposes, such as internal
          research. To learn more about how we track and use your information,
          see the “Use of Cookies and Other Tracking Technologies” section
          below.
        </li>
        <li>
          Advertise - Orkestra App does not contain or allow in-app targeted
          advertising at this time. We also do not use push notifications or
          in-app notifications to display advertisements to our users.
        </li>
        <li>
          Store data - We store data on servers hosted by Amazon Web Services
          (“AWS”). We use appropriate technical, administrative, and physical
          measures to secure your data during storage.
        </li>
        <li>
          Legal proceedings and requirements - We use data to investigate or
          address claims or disputes relating to use of Orkestra’s services, to
          satisfy requirements under applicable laws, regulations, or operating
          licenses or agreements, or pursuant to legal process or governmental
          request, including from law enforcement. Orkestra performs the above
          activities on the grounds that they are necessary for purposes of
          Orkestra’s legitimate interests in investigating and responding to
          claims and disputes relating to use of Orkestra’s services and
          features, and/or necessary for compliance with applicable legal
          requirements.
        </li>
      </ol>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Share and disclosure information"
      ></HeaderWithCicle>
      <p>
        Some of Orkestra’s services and features require that we share data with
        other users. We may also share such data with third-party services and
        partners, for legal reasons or in connection with claims or disputes. We
        share information only in the manner described below:
      </p>
      <ol>
        <li>
          Other users - If you register or access our Platform through a
          Orkestra Client, certain information about you including your name,
          contact info, photograph, content and past use of your account may
          become accessible to that Client and other individuals with whom the
          Client shares access. If you are a Client managing a particular group
          of users within our Platform, we may share your contact information
          with current or past users, for the purpose of facilitating
          Platform-related requests.
        </li>
        <li>
          Marketing and advertising – We do not sell information about you to
          advertisers or other third parties. We do not currently share your
          information with third parties for marketing purposes, but if that
          changes we will obtain your consent first.
        </li>
        <li>
          Mergers and acquisitions - If your personal information is transferred
          to a party unaffiliated with Orkestra as part of merger, acquisition,
          or sale of all or a portion of our assets, we will provide you with
          notice prior to transferring your personal information to the new
          entity. Notice will be provided directly through our Platform.
        </li>
        <li>
          Legal purposes - We disclose your information when we believe that
          disclosure is (1) reasonably necessary to comply with any applicable
          law, regulation, subpoena, legal process, or enforceable governmental
          request; (2) necessary to enforce the provisions of the Notice; (3)
          required to enforce our Terms of Service, including investigation of
          potential violations; or (4) necessary to protect against harm to the
          rights, property, or safety of Orkestra, our users, or the public as
          required or permitted by law.
        </li>
        <li>
          Third-party services and business partners - Third party services
          provide us with information needed for core aspects of the Orkestra
          Platform, as well as for additional services, programs, loyalty
          benefits, and promotions that can enhance your Orkestra experience.
          These third-party services include background check providers,
          insurance partners, financial service providers, marketing providers,
          and other businesses. We use the following sub-processors to operate
          our Platform. This list was last updated on November 8th, 2022.
        </li>
      </ol>
      <table>
        <tr>
          <th>Third-Party Service or Vendor</th>
          <th>Type of Service</th>
          <th>Location</th>
          <th>Website</th>
        </tr>
        <tr>
          <td>Amazon Web Services</td>
          <td>Cloud storage and Cloud Infrastructure</td>
          <td>USA</td>
          <td>https://aws.amazon.com/</td>
        </tr>
        <tr>
          <td>G Suite</td>
          <td>Business communications</td>
          <td>USA</td>
          <td>https://gsuite.google.com/</td>
        </tr>
        <tr>
          <td>Google APIs</td>
          <td>Cloud and Map Services</td>
          <td>USA</td>
          <td>https://cloud.google.com/apis/</td>
        </tr>
        <tr>
          <td>Elastic</td>
          <td>Search Database management</td>
          <td>USA</td>
          <td>https://www.elastic.co/</td>
        </tr>
        <tr>
          <td>Rackspace Cloud</td>
          <td>Cloud Storage and DNS management</td>
          <td>USA</td>
          <td>https://mycloud.rackspace.com/home</td>
        </tr>
        <tr>
          <td>New Relic</td>
          <td>Application performance management</td>
          <td>USA</td>
          <td>https://newrelic.com/</td>
        </tr>
      </table>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Use of Cookies and Other Tracking Technologies"
      ></HeaderWithCicle>
      <p>
        Cookies are small text files that are stored on your browser or device
        by websites, apps, online media, and advertisements that are used to
        remember your browser or device during and across website visits.
        Cookies allow the collection of data related to browsing depending on
        the type of device used, the authorizations granted by customers through
        the settings of their devices and the functionalities used in each
        application. Orkestra may use its own or third-party cookies on the
        Platform. We also utilize other technologies that may identify you or
        the devices you use.
      </p>
      <p>
        We use cookies to collect your personal and other information as you
        navigate our Platform. Cookies help make interactions with our Platform
        easier and faster for our users. Check below the types used by Orkestra:
      </p>
      <ol>
        <li>
          operation - to guarantee the correct access and operation of the
          applications;
        </li>
        <li>
          authentication - to recognize customers, enabling their access,
          including areas of restricted access;
        </li>
        <li>
          security - to assist in the monitoring and detection of unauthorized
          activities, in the prevention of fraud and in the protection of the
          information of customers, Orkestra and third parties;
        </li>
        <li>
          research, analysis and performance - to verify, measure and analyze
          the audience, performance and use of applications by customers;
        </li>
      </ol>
      <p>
        The Customer can disable or delete cookies, as well as other data
        collection technologies, in the settings of their browser and in the
        operating system settings of their device, with the exception of
        operating cookies which, if disabled, will not allow the use of the
        Platform. We only remind you that if certain cookies are disabled, the
        Platform or some of its features or functionality may not function
        properly.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="“Do-Not-Track” and Targeted Ads"
      ></HeaderWithCicle>
      <p>
        We do not respond to web browser “Do-Not-Track” signals. We do not serve
        targeted advertisements on our Platform at this time. However, you may
        see targeted ads from us on other websites based on your browsing
        history or other online activities.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Your Choices with Regards to Orkestra"
      ></HeaderWithCicle>
      <p>
        Where appropriate or legally required, we will describe how we use
        personal information, so you can make choices about how your data is
        used. You can notify us of your preferences during the information
        collection process and change your selection at any time by contacting
        us directly.
      </p>
      <ol>
        <li>
          Geolocation (drivers) – We collect your geographic location when you
          use our Platform. We use location data to track delivery statuses for
          clients and source delivery routes for drivers. We also use it to
          display delivery history and to understand and resolve support
          tickets. As a default, for the best service available the app asks you
          to turn on location services "Allow only while using the app". You can
          restrict our access to and collection of your location information by
          disabling location-sharing on your device, located in your Orkestra
          account settings or in your device (e.g., mobile phone) settings.
          Please note that disabling location services may impact how our
          Platform functions.
        </li>
        <li>
          Marketing emails – You can choose to stop receiving marketing emails
          by following the unsubscribe instructions included in these emails, or
          by using the email address listed in the “Contact Us” section below.
          If you opt out of receiving marketing emails, we may still send you
          non-promotional emails, such as emails about your Orkestra account.
        </li>
        <li>
          Push notifications – You can opt-out of receiving push notifications
          through your device settings. Please note that opting-out of receiving
          push notifications may impact how our Platform functions.
        </li>
        <li>
          Mobile application information – You can stop Orkestra from collecting
          information through the Orkestra App by uninstalling the mobile app.
          You can use the standard uninstall processes available on your mobile
          device or via the mobile application marketplace or network. You can
          also contact us to deactivate your account using the email address
          listed in the “Contact Us” section below.
        </li>
      </ol>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Your Rights"
      ></HeaderWithCicle>
      <p>
        You have certain rights in connection with the personal information we
        obtain about you. To update your preferences, correct your information,
        limit the communications you receive from us, or submit a request to
        exercise your rights, please contact us as specified in the “Contact Us”
        section.
      </p>
      <p>As required by law, you have the right to:</p>
      <ol>
        <li>
          Request access to certain personal information we maintain about you;
        </li>
        <li>
          Request that we update, correct, amend, erase or restrict certain
          personal information;
        </li>
        <li>Object to some forms of automated decision-making or profiling;</li>
        <li>File a complaint with a relevant Data Protection Authority; and</li>
        <li>Exercise your right to data portability.</li>
      </ol>

      <p>
        Where our Platform is administered for you by a Client, you may need to
        first contact the Client to assist with your requests. For all other
        requests, you can contact us as provided in the “Contact Us” section
        below to request assistance.
      </p>
      <p>
        In some circumstances you can withdraw consent you previously provided
        to us or object to the processing of your personal information, and we
        will apply your preferences moving forward.
      </p>
      <p>
        To help protect your privacy and maintain security, we may take steps to
        verify your identity before granting you access to the information. We
        may also decline your access request, but in the event we do, we will
        provide an explanation for our decision. Your request and choices may be
        limited in certain cases: for example, if fulfilling your request would
        reveal information about another person, or if you ask to delete
        information which we or your administrator are permitted by law or have
        compelling legitimate interests to keep. If you have unresolved
        concerns, you may have the right to complain to a data protection
        authority in the country where you live, where you work or where you
        feel your rights were infringed.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Children’s Privacy"
      ></HeaderWithCicle>
      <p>
        Our Orkestra Platform is not directed to children under the age of 13,
        and we do not knowingly collect information from children under the age
        of 13.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="How Long We Retain Information"
      ></HeaderWithCicle>
      <p>
        How long we keep information we collect about you depends on the type of
        information, as described below. We will either delete or anonymize your
        information or, if this is not possible (for example, the information is
        stored in backup archives), then we will securely store your information
        and isolate it from any further use until deletion is possible.
      </p>
      <ol>
        <li>
          Account information – We retain user profile and transaction
          information for as long as you maintain your Orkestra account. For
          drivers and delivery partners, we also retain vehicle and background
          check information (to the extent permitted by law) for as long as they
          maintain their Orkestra account. We also retain some of your
          information as necessary to comply with our legal obligations, to
          resolve disputes, to enforce our agreements, to support business
          operations, and to continue to develop and improve our Platform. Where
          we retain information for the improvement and development of our
          Platform, we take steps to eliminate information that directly
          identifies you. If our Platform is made available to you through our
          Clients, we retain your information as long as required by the Client.
          For more information, see "Information We Receive from Other Sources"
          above.
        </li>
        <li>
          Marketing information – If you have elected to receive marketing
          emails from us, we retain information about your marketing preferences
          for a reasonable period of time from the date you last expressed
          interest in our Platform, such as when you last opened an email from
          us or ceased using your Orkestra account. We retain information
          derived from cookies and other tracking technologies for a reasonable
          period of time from the date the information was created.
        </li>
        <li>
          Marketing information – If you have elected to receive marketing
          emails from us, we retain information about your marketing preferences
          for a reasonable period of time from the date you last expressed
          interest in our Platform, such as when you last opened an email from
          us or ceased using your Orkestra account. We retain information
          derived from cookies and other tracking technologies for a reasonable
          period of time from the date the information was created.
        </li>
      </ol>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Security Safeguards"
      ></HeaderWithCicle>
      <p>
        We use reasonable and appropriate physical, technical, and
        administrative safeguards to protect your information from unauthorized
        use, access, loss, misuse, alteration, or destruction. We also require
        that third party service providers acting on our behalf or with whom we
        share your information also provide appropriate security measures in
        accordance with industry standards. Notwithstanding our security
        safeguards, it is impossible to guarantee absolute security in all
        situations. If you have any questions about the security of our
        Platform, please contact us using the email address listed in the
        “Contact Us” section below.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Changes to this Privacy Notice"
      ></HeaderWithCicle>
      <p>
        We periodically update this Notice to describe new features, products,
        or services, and how those changes affect our use of your information.
        If we make material changes to this Notice, we will provide notification
        through our services and/or notify you directly. We encourage you to
        review this Notice for updates each time you use our Platform.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Third Party Services, Applications, and Websites"
      ></HeaderWithCicle>
      <p>
        Certain third party services, websites, or applications you use, or
        navigate to from our Platform may have separate user terms and privacy
        policies that are independent of this Notice. This includes, for
        example, websites owned and operated by our customers or partners. We
        are not responsible for the privacy practices of these third party
        services or applications. We recommend carefully reviewing the user
        terms and privacy statement of each third party service, website, and/or
        application prior to use.
      </p>
    </div>

    <div class="shy-policy-section">
      <HeaderWithCicle
        textSize="22pt"
        backgroundSize="40px"
        title="Contacting Us"
      ></HeaderWithCicle>
      <p>
        If you have questions about this Notice or our information handling
        practices, please contact us at
        <strong>support@orkestraos.com</strong> or write to us at:
      </p>
      <div class="shy-address">
        <p>1133 15th St Nw,</p>
        <p>Washington, DC 20005</p>
      </div>
      <p>United States</p>
    </div>
  </section>
  <SectionNine class="padding-sectiontwo" />
</template>

<script>
import HeaderOptions from "../sectionOne/components/headerOptions.vue";
import HeaderWithCicle from "../generalComponents/HeaderWithCicle.vue";
import SectionNine from "../sectionNine/SectionNine.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    HeaderOptions,
    HeaderWithCicle,
    SectionNine,
  },
};
</script>

<style>
@import "./PrivacyPolicy.css";
</style>
