<template>
    <section class="s3-container--styled">
        <h2 class="text-header--styled --reduce-line-height-s5 utils-s1--tablet">{{ $t("message.s3-title--desktop-p1") }}<br> {{ $t("message.s3-title--desktop-p2") }}</h2>
        <h2 class="text-header--styled --reduce-line-height-bigger utils-desktop">{{ $t("message.s3-title--mobile-p1") }}<br>{{ $t("message.s3-title--mobile-p2") }}<br>{{ $t("message.s3-title--mobile-p3") }}</h2>
        <!-- The banners are handle for tablet and mobile -->
        <img v-on:click="redirectURL" class="cursor--pointer banner--s3--styled utils-general--mobile" alt="s3-banner-photo" src="../../assets/s3-content.png" />
        <img v-on:click="redirectURL" class="cursor--pointer utils-general-show--tablet" alt="s3-banner-photo" src="../../assets/s3-banner--mobile.png" />
    </section>
</template>

<script>
export default {
    name: 'SectionThree',
    methods: {
        redirectURL() {
            window.open('https://forms.gle/8nWgKpD3soHffVbW6')
        }
    }
}
</script>

<style>
@import './section-three.css';
.cursor--pointer {
    cursor: pointer;
}
</style>
