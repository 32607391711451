<template>
    <aside id="section2" class="container--s6--styled">
        <div class="utils-s1--tablet center--auto title--s5--margin">
            <HeaderWithCicle class="title--styled-s7 ---extra-margin-s5" textSize="22pt" backgroundSize="40px" title="IMPACTO"></HeaderWithCicle>
        </div>
        <!-- For desktop -->
        <aside class="utils-s1--tablet s5-container--styled">
            <section class="s5-inner-setions--styled s5--no--align --padding--force--s5">
                <img class="smart-sized--logo-s6" alt="smart-logistic-logo" src="../../assets/smartlogisticsmall.png" />
                <div class="text-header--styled text--align-left --special-s5--line-height margin--text-s5 --reduce-line-height-s5">{{ $t("message.s5-title--p1") }}<br> {{ $t("message.s5-title--p2") }}</div>
                <p class="text-s5--inner --subtitle--special-padding-s5">{{ $t("message.s5-subtitle--p1") }}<br>{{ $t("message.s5-subtitle--p2") }}</p>
                <img v-on:click="redirectURL" class="cursor--pointer smart-sized margin--button" alt="smart-logistic-logo" src="../../assets/button-s5-orkestra.png" />
            </section>
            <section class="s5-inner-setions--styled--img">
                <img class="image--cycle-s5" alt="s5-oval-img" src="../../assets/s5-img-circle.png" />
                <Vue3Lottie :key="autoPlay" v-observe-visibility="visibilityChanged" class="resized---lines cursor--pointer s5---align-self-" :loop="false" :animationData="linesJson" :autoPlay="autoPlay" />
            </section>
        </aside>
        <!-- For tablet -->
        <aside class="utils-desktop container--tablet--s5">
            <img class="smart-sized--logo-s6" alt="smart-logistic-logo" src="../../assets/smartlogisticsmall.png" />
            <h3 class="text-header--styled text--align-left margin--text-s5 --reduce-line-height-bigger text--align--s7">{{ $t("message.s5-title--p1") }}<br>{{ $t("message.s5-title--p2") }}</h3>
            <p class="text-s5--inner">{{ $t("message.s5-subtitle--p1") }}<br>{{ $t("message.s5-subtitle--p2") }}</p>
            <img class="impacto-header" alt="impacto-header" src="../../assets/impacto-s5-tablet.png" />
            <img v-on:click="redirectURL" class="image--cycle-s5" alt="s5-oval-img" src="../../assets/s5--img-mobile.png" />
        </aside>
    </aside>
</template>

<script>
/* eslint-disable no-unused-vars */
import HeaderWithCicle from '../generalComponents/HeaderWithCicle.vue'

import linesJson from '../../assets/lottieAnimations/Arrows.json'

export default {
    name: 'SectionFive',
    components: {
        HeaderWithCicle
    },
    data() {
        return {
            linesJson,
            autoPlay: false
        }
    },
    methods: {
        redirectURL() {
            window.open('https://forms.gle/8nWgKpD3soHffVbW6')
        },
        visibilityChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlay = true
            }
        }
    }
}
</script>

<style>
@import './section-five.css';
.resized---lines {
    width: 130px;
}
.title--s5--margin {
    margin: 50px 0px;
}
.--subtitle--special-padding-s5 {
    padding-top: 20px;
}
.--resized-s5-mobile {
    width: 60%;
}
.---extra-margin-s5 {
    margin-top: 100px !important;
}
.impacto-header {
    width: 150px;
}
.--reduce-line-height-s5 {
    line-height: 70px;
}
.--special-s5--line-height {
    line-height: 62px;
}
.image--background-s5 {
    background-image: url(../../assets/s5-s4--backgroun-img.png);
    background-repeat: no-repeat;
    background-position: bottom;
}
.padding-orjestra-s5-mobile {
    padding: 45px 0px;
}
.container--s6--styled {
    padding-bottom: 600px;
}

@media (max-width: 1280px) {
    .image--cycle-s5 {
        width: 400px;
    }
}
@media (max-width: 1010px) {
    .utils-s1--tablet {
        display: none;
    }
    .container--tablet--s5 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 65px;
        align-items: center;
    }
    .image--cycle-s5 {
        width: 600px;
        margin-left: 90px;
    }
    .smart-sized {
        width: 200px;
        height: 30px;
    }
}
@media (min-width: 1011px) {
    .utils-desktop {
        display: none;
    }
}
@media (max-width: 800px) {
    .image--cycle-s5 {
        width: 100%;
        margin: auto 0;
    }
    .---extra-margin-s5 {
        margin-top: 0;
    }
    .--reduce-line-height-bigger {
        line-height: 50px;
    }
    .--reduce-line-height-s5 {
        line-height: 35px;
    }
    .title--s5--margin {
        margin: 0px;
    }
    .image--background-s5 {
        background-image: url(../../assets/background-mobile--s5.png);
        background-position: bottom;
    }
    .container--s6--styled {
        padding-bottom: 0px;
    }
}
@media (max-width: 684px) {
    .image--cycle-s5 {
        width: 100%;
    }
}
</style>
