<template>
    <!--  -->
    <h3 class="text-header--styled text-styled--left --reduce-line-height-s5 utils-s1--tablet">{{ $t("message.s6-title--p1") }}<br>{{ $t("message.s6-title--p2") }}</h3>
    <h3 class="text-header--styled text-styled--left --reduce-line-height-bigger utils-desktop --s6--top-margin">{{ $t("message.s6-title--p1") }}<br>{{ $t("message.s6-title--p2") }}</h3>
    <p class="text-s6--inner">{{ $t("message.s6-subtitle-p1") }}<br>{{ $t("message.s6-subtitle-p2") }}<br>{{ $t("message.s6-subtitle-p3") }}</p>
    <div id="section3" class="center--auto --force--padding--casos">
        <HeaderWithCicle class="title--styled-s7 --margin--important-s6" textSize="18pt" backgroundSize="35px" title="CASOS Y RESEÑAS"></HeaderWithCicle>
    </div>
    <!-- Banner are different for mobile and other -->
    <img class="img--resized-s6-bg utils-general--mobile" alt="cases-section-six" src="../../assets/cases-s6-inf-txt.png" />
    <img class="img-s6--sized utils-general-show--tablet" alt="cases-section-six" src="../../assets/mobile--cases-s.png" />
    <div id="section4" class="--force--padding-s7"></div>
    <div class="line--divisor-section-s8 margin--top-s6"></div>
</template>

<script>
import HeaderWithCicle from '../generalComponents/HeaderWithCicle.vue'

export default {
    name: 'SectionSix',
    components: {
        HeaderWithCicle
    }
}
</script>

<style>
@import './section-six.css';
.--force--padding--casos {
    padding-top: 140px;
    padding-bottom: 60px
}
</style>
