<template>
    <aside class="container-s9-background">
        <!-- <div class="s9-fixed--left-padding s9--container-titles">
            <h3 class="text-header--styled utils-general--mobile --change-s9-font-title align--text-left --reduce-line-height-s5 --no-margin">We are<br>Orkestra</h3>
            <h3 class="text-header--styled utils-general-show--tablet --change-s9-font-title">We are Orkestra</h3>
            <HeaderWithCicleWhite class="s9--styled--team utils-general--mobile" textSize="18pt" backgroundSize="35px" title="THE TEAM"></HeaderWithCicleWhite>
        </div> -->
        <!-- <section class="display--desktop ----forcer-margin"> -->
            <!-- <p class="--no-margin text-s9-subheader--styled">Un equipo de líderes con experiencia<br>regional y visión global</p> -->
            <!-- <aside class="s9--footer-container">
                <div class="s9--footer--inner-container">
                    <p class="text-header--styled --change-s9-font-inner">GENERAL MANAGEMENT</p>
                    <p v-on:click="changeSelected(0)" :class="`s9--inner-styled--text cursor--pointer ${selected===0 ? 'underline' : ''}`">Miguel Torres <span class="--span--padding-left-0">CEO Co-Founder</span></p>
                    <p v-on:click="changeSelected(1)" :class="`s9--inner-styled--text cursor--pointer ${selected===1 ? 'underline' : ''}`">Luis Loaiza <span class="--span--padding-left-1">CPO Co-Founder</span></p>
                    <p v-on:click="changeSelected(2)" :class="`s9--inner-styled--text cursor--pointer ${selected===2 ? 'underline' : ''}`">Marco Calderón <span class="--span--padding-left-2">CTO</span></p>
                    <p class="--s9-separator text-header--styled --change-s9-font-inner">ORKESTRA TEAM</p>
                    <p v-on:click="changeSelected(3)" :class="`s9--inner-styled--text cursor--pointer ${selected===3 ? 'underline' : ''}`">Estefanía Yánez <span class="--span--padding-left-3">Regional Business Manager</span></p>
                    <p v-on:click="changeSelected(4)" :class="`s9--inner-styled--text cursor--pointer ${selected===4 ? 'underline' : ''}`">Génesis Blanco <span class="--span--padding-left-4">CS Manager</span></p>
                    <p v-on:click="changeSelected(5)" :class="`s9--inner-styled--text cursor--pointer ${selected===5 ? 'underline' : ''}`">Denny Schuldt <span class="--span--padding-left-5">UX Director</span></p>
                    <p v-on:click="changeSelected(6)" :class="`s9--inner-styled--text cursor--pointer ${selected===6 ? 'underline' : ''}`">Pedro Grossi <span class="--span--padding-left-6">Brasil Business Dev</span></p>
                    <p v-on:click="changeSelected(7)" :class="`s9--inner-styled--text cursor--pointer ${selected===7 ? 'underline' : ''}`">Ailén Verón <span class="--span--padding-left-7">QA & Customer Support</span></p>
                    <p v-on:click="changeSelected(8)" :class="`s9--inner-styled--text cursor--pointer ${selected===8 ? 'underline' : ''}`">Douglas Salustiano <span class="--span--padding-left-8">Visual Media Design Leader</span></p>
                    <p v-on:click="changeSelected(9)" :class="`s9--inner-styled--text cursor--pointer ${selected===9 ? 'underline' : ''}`">Sairys Bolivar <span class="--span--padding-left-9">QA Leader</span></p>
                    <p v-on:click="changeSelected(10)" :class="`s9--inner-styled--text cursor--pointer ${selected===10 ? 'underline' : ''}`">Justhine Tumbaco <span class="--span--padding-left-10">Full-Stack Developer</span></p>
                </div>
                <div class="s9--footer--inner-container --align-center-s9">
                    <img class="s9--footer-resized" alt="founder--img" src="../../assets/Estefa-photo.png" />
                    <p class="text-header--styled --change-s9-font-name --no-margin">Estefanía Yánez</p>
                    <p class="s9--inner-styled--text">Brindar experiencias excepcionales y solu-<br>ciones innovadoras, es nuestra misión</p>
                </div>
            </aside> -->
            <!-- <div class="s9--big-separator"></div> -->
            <!-- <section class="grid--lastpart-footer-s9"> -->
                <!-- <div class="s9--footer--inner-container"> -->
                    <!-- <p class="text-header--styled --change-s9-font-inner">{{ $t("message.s9-title1") }}</p> -->
                    <!-- <div class="s9--separator--info --change--inner-separator-info"></div> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">Orkestra OS</p> -->
                <!-- </div> -->
                <!-- <div class="s9--footer--inner-container"> -->
                    <!-- <p class="text-header--styled --change-s9-font-inner">{{ $t("message.s9-title2") }}</p> -->
                    <!-- <div class="s9--separator--info --change--inner-separator-info"></div> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">{{ $t("message.s9-subtitle1") }}</p> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">Blog</p> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">Developers</p> -->
                <!-- </div> -->
                <!-- <div class="s9--footer--inner-container"> -->
                    <!-- <p class="text-header--styled --change-s9-font-inner">{{ $t("message.s9-title3") }}</p> -->
                    <!-- <div class="s9--separator--info --change--inner-separator-info"></div> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">{{ $t("message.s9-subtitle2") }}</p> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" v-on:click="goToWorkWithUs">{{ $t("message.s9-subtitle3") }}</p> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">{{ $t("message.s9-subtitle4") }}</p> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">{{ $t("message.s9-subtitle5") }}</p> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" :onClick="goToNotFound">{{ $t("message.s9-subtitle6") }}</p> -->
                <!-- </div> -->
            <!-- </section> -->
        <!-- </section> -->
        <!-- Images change for mobile -->
        <!-- <section class="hide--s9---force s9--container--tablet--styled"> -->
            <!-- <p class="--no-margin text-s9-subheader--styled">Un equipo de líderes con experiencia<br>regional y visión global</p>
            <HeaderWithCicleWhite class="s9--styled--team" textSize="18pt" backgroundSize="35px" title="THE TEAM"></HeaderWithCicleWhite> -->
            <!-- <section class="---background-color"> -->
                <!-- <img class="s9--footer-resized" alt="founder--img" src="../../assets/Estefa-photo.png" />
                <p class="text-header--styled --change-s9-font-name --no-margin">Estefanía Yánez</p>
                <p class="s9--inner-styled--text">Brindar experiencias excepcionales y solu-<br>ciones innovadoras, es nuestra misión</p>
                <p class="text-header--styled s9--utils-margin --change-s9-font-inner">GENERAL MANAGEMENT</p>
                <div class="----inner-container">
                    <p v-on:click="changeSelected(0)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===0 ? 'underline' : ''}`">Miguel Torres<br><span class="--decrease-font">CEO Co-Founder</span></p>
                    <p v-on:click="changeSelected(1)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===1 ? 'underline' : ''}`">Luis Loaiza<br><span class="--decrease-font">CPO Co-Founder</span></p>
                    <p v-on:click="changeSelected(2)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===2 ? 'underline' : ''}`">Marco Calderón<br><span class="--decrease-font">CTO</span></p>
                </div>
                <p class="--s9-separator text-header--styled --change-s9-font-inner">ORKESTRA TEAM</p>
                <div class="----inner-container">
                    <p v-on:click="changeSelected(3)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===3 ? 'underline' : ''}`">Estefanía Yánez<br><span class="--decrease-font">Regional Business Manager</span></p>
                    <p v-on:click="changeSelected(4)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===4 ? 'underline' : ''}`">Génesis Blanco<br><span class="--decrease-font">CS Manager</span></p>
                    <p v-on:click="changeSelected(5)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===5 ? 'underline' : ''}`">Denny Schuldt<br><span class="--decrease-font">UX Director</span></p>
                    <p v-on:click="changeSelected(6)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===6 ? 'underline' : ''}`">Pedro Grossi<br><span class="--decrease-font">Brasil Business Dev</span></p>
                    <p v-on:click="changeSelected(7)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===7 ? 'underline' : ''}`">Ailén Verón<br><span class="--decrease-font">QA & Customer Support</span></p>
                    <p v-on:click="changeSelected(8)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===8 ? 'underline' : ''}`">Douglas Salustiano<br><span class="--decrease-font">Visual Media Design Leader</span></p>
                    <p v-on:click="changeSelected(9)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===9 ? 'underline' : ''}`">Sairys Bolivar<br><span class="--decrease-font">QA Leader</span></p>
                    <p v-on:click="changeSelected(10)" :class="`s9--inner-styled--text text----align-left cursor--pointer ${selected===10 ? 'underline' : ''}`">Justhine Tumbaco<br><span class="--decrease-font">Full-Stack Developer</span></p>
                </div> -->
                <!-- <div class="s9--footer--inner-container text----align-left ----util-margin"> -->
                    <!-- <p class="text-header--styled --change-s9-font-inner">{{ $t("message.s9-title1") }}</p> -->
                    <!-- <div class="s9--separator--info --change--inner-separator-info"></div> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">Orkestra OS</p> -->
                <!-- </div> -->
                <!-- <div class="s9--footer--inner-container text----align-left ---s9-final-margin"> -->
                    <!-- <p class="text-header--styled --change-s9-font-inner">{{ $t("message.s9-title2") }}</p> -->
                    <!-- <div class="s9--separator--info --change--inner-separator-info"></div> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">{{ $t("message.s9-subtitle1") }}</p> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">Blog</p> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">Developers</p> -->
                <!-- </div> -->
                <!-- <div class="s9--footer--inner-container text----align-left ---padding-final"> -->
                    <!-- <p class="text-header--styled --change-s9-font-inner">{{ $t("message.s9-title3") }}</p> -->
                    <!-- <div class="s9--separator--info --change--inner-separator-info"></div> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">{{ $t("message.s9-subtitle2") }}</p> -->
                    <!-- <p class="s9--inner-styled--text cursor--pointer" v-on:click="goToWorkWithUs">{{ $t("message.s9-subtitle3") }}</p> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">{{ $t("message.s9-subtitle4") }}</p> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">{{ $t("message.s9-subtitle5") }}</p> -->
                    <!-- <p class="s9--inner-styled--text" :onClick="goToNotFound">{{ $t("message.s9-subtitle6") }}</p> -->
                <!-- </div> -->
            <!-- </section>           -->
        <!-- </section> -->
    </aside>
</template>

<script>
// Need it when we will uncomment the orkestra team
// import HeaderWithCicleWhite from '../generalComponents/HeaderWithCicleWhite.vue'

export default {
    name: 'SectionNine',
    data() {
        return {
            selected: 3
        }
    },
    methods: {
        changeSelected(key) {
            this.selected = key
        },
        goToWorkWithUs() {
            window.open(`mailto:belen@shippify.co`, '__self')
        },
        goToNotFound() {
            this.$router.push('/notFound')
        }
    }
}
</script>

<style>
.----forcer-margin {
    margin-bottom: 0 !important;
}
@media (min-width: 981px) {
    .hide--s9---force {
        display: none !important;
    }
}
@media (max-width: 980px) {
    .hide--s9---force {
        display: block;
    }
}
.underline {
    border-bottom: .1em solid;
}
.container-s9-background {
    background-image: url(../../assets/backgroundS9.png);
}
.grid--lastpart-footer-s9 {
    display: grid;
    margin: 0 8%;
    margin-top: 200px;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 150px;
}
.----inner-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.---s9-final-margin {
    margin-top: 40px;
}
.s9--inner-styled--text {
    font-family: 'BrotherRegular', Courier, monospace;
    font-size: 13pt;
}
.s9--big-separator {
    height: 1px;
    width: 75%;
    background-color: #b3b0b0;
    margin: 120px 270px;
}
.--align-center-s9 {
    align-items: center;
}
.s9--separator--info {
    height: 1px;
    background-color: black;
    width: 100%;
}
.--change-s9-font-title {
    font-size: 59pt;
}
.--change--inner-separator-info {
    width: 60%;
}
.--change-s9-font-name  {
    font-size: 40pt;
}
.--change-s9-font-inner {
    font-size: 15pt;
}
/** SPACING BETWEEN INFO */
.--span--padding-left-0 {
    padding-left: 220px;
}
.--span--padding-left-1 {
    padding-left: 240px;
}
.--span--padding-left-2 {
    padding-left: 198px;
}
.--span--padding-left-3 {
    padding-left: 195px;
}
.--span--padding-left-4 {
    padding-left: 199px;
}
.--span--padding-left-5 {
    padding-left: 201px;
}
.--span--padding-left-6 {
    padding-left: 217px;
}
.--span--padding-left-7 {
    padding-left: 225px;
}
.--span--padding-left-8 {
    padding-left: 167px;
}
.--span--padding-left-9 {
    padding-left: 212px;
}
.--span--padding-left-10 {
    padding-left: 174px;
}
/** FINISH */
.s9--styled--team {
    padding-top: 160px;
    padding-left: 50px;
}
.s9-fixed--left-padding {
    padding-left: 270px;
    margin: 0;
}
.--s9-separator {
    margin-top: 80px;
}
.align--text-left {
    text-align: left;
}
.s9--container-titles {
    display: grid;
    grid-template-columns: 450px 200px;
}
.--no-margin {
    margin: 0;
}
.text-s9-subheader--styled {
    text-align: left;
    font-family: 'BrotherBlod', Courier, monospace;
    font-size: 18pt;
    color: #727272;
    padding-left: 270px;
}
.s9--container--tablet--styled {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23px;
}
.---background-color {
    background-color: #faff88;
    width: 100%;
}
.img--styled-s9 {
    padding-top: 50px;
    padding-left: 130px;
}
.img-s9--sized {
    width: 65%;
}
.s9--footer-resized {
    width: 250px;
    height: 230px;
}
.s9--footer-container {
    display: grid;
    padding-left: 270px;
    margin-top: 100px;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
}
.s9--footer--inner-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
}
.text----align-left {
    align-self: flex-start;
    margin-left: 12%;
    text-align: left;
}

@media (max-width: 1280px) {
    .img--styled-s9 {
        width: 80%;
    }
}
@media (max-width: 680px) {
    .container-s9-background {
        background-image: none;
    }
    .s9--container-titles {
        grid-template-columns: 1fr;
    }
    .--s9-separator {
        margin-top: 20px;
    }
    .----util-margin {
        margin-top: 90px;
    }
    .---padding-final {
        margin-top: 40px;
        padding-bottom: 80px;
    }
    .s9--styled--team {
        padding: 0;
    }
    .text--- {
        align-self: flex-start;
        padding-left: 15%;
    }
    .s9--inner-styled--text {
        font-size: 12pt;
    }
    .--decrease-font {
        font-size: 10pt;
    }
    .--change-s9-font-inner {
        font-size: 12pt;
    }
    .s9--utils-margin {
        margin-top: 30px;
    }
    .--change-s9-font-name  {
        font-size: 20pt;
    }
    /** SPACING BETWEEN INFO */
    .--span--padding-left-0 {
        padding-left: 10%;
    }
    .--span--padding-left-1 {
        padding-left: 10%;
    }
    .--span--padding-left-2 {
        padding-left: 10%;
    }
    .--span--padding-left-3 {
        padding-left: 10%;
    }
    .--span--padding-left-4 {
        padding-left: 10%;
    }
    .--span--padding-left-5 {
        padding-left: 10%;
    }
    .--span--padding-left-6 {
        padding-left: 10%;
    }
    .--span--padding-left-7 {
        padding-left: 10%;
    }
    .--span--padding-left-8 {
        padding-left: 10%;
    }
    /** FINISH */
    .s9-fixed--left-padding {
        padding-left: 0;
    }
    .text-s9-subheader--styled {
        text-align: center;
        padding: 0;
        color: black;
        padding: 20px 0;
        font-family: 'BrotherMedium', Courier, monospace;
        font-size: 10pt;
    }
    .img--sized-s9 {
        width: 100%;
    }
    .img--styled-s9 {
        width: 100%;
    }
    .--change-s9-font-title {
        text-align: center;
        font-size: 25pt;
    }
}
</style>
