<template>
    <section id="section0" v-on:click="checkIsModalLangOpen()" class="section--styled">
        <h2 class="text-header--styled utils-s1--tablet">{{ $t("message.s2-title") }}</h2>
        <h2 class="text-header--styled --reduce-line-height-bigger utils-desktop">{{ $t("message.s2-title-mobile-p1") }}<br> {{ $t("message.s2-title-mobile-p2") }}</h2>
        <!-- Subtitles -->
        <p class="margin--top text-subheader--styled utils-s1--tablet">{{ $t("message.s2-subtitle--p1") }} <span class="boled">{{ $t("message.s2-subtitle--desktop-p2") }}<br>{{ $t("message.s2-subtitle--desktop-p3") }}</span> {{ $t("message.s2-subtitle--desktop-p4") }}</p>
        <p class="margin--top text-subheader--styled utils-desktop">{{ $t("message.s2-subtitle--p1") }} <span class="boled">{{ $t("message.s2-subtitle--mobile-p2") }}<br>{{ $t("message.s2-subtitle--mobile-p3") }}</span> {{ $t("message.s2-subtitle--desktop-p4") }}</p>
        <div class="center--auto title--s5--margin">
            <HeaderWithCicle class="title--styled-s7" textSize="22pt" backgroundSize="40px" title="FEATURES"></HeaderWithCicle>
        </div>
        <!-- Cards -->
        <Card>
            <div class="first-card--style">
                <section class="inner-section-two-text-container">
                    <p class="text-header-card--styled --no-margin">{{ $t("message.s2-card1-title--p1") }}<br>{{ $t("message.s2-card1-title--p2") }}</p>
                    <p class="--no-margin inner-container-card-s2-text">{{ $t("message.s2-card1-subtitle--p1") }}<br>{{ $t("message.s2-card1-subtitle--p2") }}<br>{{ $t("message.s2-card1-subtitle--p3") }}</p>
                    <ul class="s2-margin-section-top">
                        <li class="inner-container-card-s2-text --bold">{{ $t("message.s2-card1-option1--p1") }}<br>{{ $t("message.s2-card1-option1--p1") }}</li>
                        <li class="inner-container-card-s2-text --bold">{{ $t("message.s2-card1-option2") }}</li>
                    </ul>
                </section>
                <Vue3Lottie :key="autoPlayMap" v-observe-visibility="visibilityMapChanged" :animationData="mapJson" class="card--size cursor--pointer" :loop="false" :autoPlay="autoPlayMap" />
            </div>
        </Card>
        <!-- Two Cards Tablet -->
        <TwoCards class="margin-spacing--card utils-s1--tablet" />
        <!-- Two Cards Mobile -->
        <Card class="utils-desktop">
            <div class="first-card--style">
                <p class="text-header-card--styled --no-margin">{{ $t("message.s2-card2-title") }}</p>
                <Vue3Lottie :key="autoPlayStats" v-observe-visibility="visibilityStatsChanged" class="card--size cursor--pointer" :loop="false" :animationData="statsJsonMobile" :autoPlay="autoPlayStats" />
                <p class="--no-margin inner-container-card-s2-text" >{{ $t("message.s2-card2-subtitle--mobile-p1") }}<br> {{ $t("message.s2-card2-subtitle--mobile-p2") }}<br> {{ $t("message.s2-card2-subtitle--mobile-p3") }}</p>
            </div>
        </Card>
        <Card class="utils-desktop">
            <div class="first-card--style">
                <p class="text-header-card--styled">{{ $t("message.s2-card3-title") }}</p>
                <Vue3Lottie :key="autoPlayReporte" v-observe-visibility="visibilityReporteChanged" class="card--size cursor--pointer" :loop="false" :animationData="reporteJsonMobile" :autoPlay="autoPlayReporte" />
                <p class="--no-margin inner-container-card-s2-text">{{ $t("message.s2-card3-subtitle--mobile-p1") }}<br> {{ $t("message.s2-card3-subtitle--mobile-p2") }}<br> {{ $t("message.s2-card3-subtitle--mobile-p3") }}<br> {{ $t("message.s2-card3-subtitle--mobile-p4") }}</p>
            </div>
        </Card>
        <!-- Rest cards -->
        <Card class="margin-spacing--card">
            <div class="first-card--style">
                <section class="inner-section-two-text-container">
                    <p class="text-header-card--styled --no-margin">{{ $t("message.s2-card4-title") }}</p>
                    <p class="--no-margin inner-container-card-s2-text">{{ $t("message.s2-card4-subtitle--p1") }}<br>{{ $t("message.s2-card4-subtitle--p2") }}<br>{{ $t("message.s2-card4-subtitle--p3") }}</p>
                    <ul class="s2-margin-section-top">
                        <li class="inner-container-card-s2-text --bold">{{ $t("message.s2-card4-list-option1") }}</li>
                        <li class="inner-container-card-s2-text --bold">{{ $t("message.s2-card4-list-option2") }}</li>
                    </ul>
                </section>
                <Vue3Lottie :key="autoPlayCalendar" v-observe-visibility="visibilityCalendarChanged" class="card--size cursor--pointer" :loop="false" :animationData="calendarJsonMobile" :autoPlay="autoPlayCalendar" />
            </div>
        </Card>
        <Card class="margin-spacing--card" :small="true">
            <div class="second-card--style">
                <section class="inner-section-two-text-container">
                    <p class="text-header-card--styled --no-margin">{{ $t("message.s2-card5-title") }}</p>
                    <p class="--no-margin inner-container-card-s2-text">{{ $t("message.s2-card5--subtitle-p1") }}<br>{{ $t("message.s2-card5--subtitle-p2") }}</p>
                </section>
                <Vue3Lottie :key="autoPlayAccounts" v-observe-visibility="visibilityAccountChanged" class="card--size cursor--pointer" :loop="false" :animationData="accountsJsonMobile" :autoPlay="autoPlayAccounts" />
            </div>
        </Card>
    </section>
</template>

<script>
/* eslint-disable no-unused-vars */
import Card from './components/Card.vue';
import TwoCards from './components/TwoCards.vue';
import HeaderWithCicle from '../generalComponents/HeaderWithCicle.vue'

// Spanish
import mapJson from '../../assets/lottieAnimations/Map.json'
import statsJson from '../../assets/lottieAnimations/stats.json'
import reporteJson from '../../assets/lottieAnimations/Reporte.json'
import calendarJson from '../../assets/lottieAnimations/Calendar.json'
import accountsJson from '../../assets/lottieAnimations/Accounts.json'

// English
import mapJsonEn from '../../assets/lottieAnimations/en/Map.json'
import statsJsonEn from '../../assets/lottieAnimations/en/stats.json'
import reporteJsonEn from '../../assets/lottieAnimations/en/Reporte.json'
import calendarJsonEn from '../../assets/lottieAnimations/en/Calendar.json'
import accountsJsonEn from '../../assets/lottieAnimations/en/Accounts.json'

// Portugues
import mapJsonPt from '../../assets/lottieAnimations/pt/Map.json'
import reporteJsonPt from '../../assets/lottieAnimations/pt/Reporte.json'
import calendarJsonPt from '../../assets/lottieAnimations/pt/Calendar.json'
import accountsJsonPt from '../../assets/lottieAnimations/pt/Accounts.json'

// Mobile
import statsJsonMobile from '../../assets/lottieAnimations/Mobile/Stats_Mobile.json'
import mapJsonMobile from '../../assets/lottieAnimations/Mobile/Maps_Mobile.json'
import reporteJsonMobile from '../../assets/lottieAnimations/Mobile/Reporte_Mobile.json'
import calendarJsonMobile from '../../assets/lottieAnimations/Mobile/Calendar_Mobile.json'
import accountsJsonMobile from '../../assets/lottieAnimations/Mobile/Accounts_Mobile.json'

export default {
    name: 'SectionTwo',
    components: {
        Card,
        TwoCards,
        HeaderWithCicle
    },
    data() {
        return {
            mapJson,
            statsJson,
            reporteJson,
            calendarJson,
            accountsJson,
            mapJsonMobile,
            reporteJsonMobile,
            calendarJsonMobile,
            statsJsonMobile,
            accountsJsonMobile,
            autoPlayMap: false,
            autoPlayCalendar: false,
            autoPlayAccounts: false,
            autoPlayStats: false,
            autoPlayReporte: false
        }
    },
    methods: {
        visibilityMapChanged(isVisible, entry) {
            this.checkLangs()
            if(isVisible) {
                this.autoPlayMap = true
            } else {
                this.autoPlayMap = false
            }
        },
        visibilityCalendarChanged(isVisible, entry) {
            this.checkLangs()
            if(isVisible) {
                this.autoPlayCalendar = true
            } else {
                this.autoPlayCalendar = false
            }
        },
        visibilityAccountChanged(isVisible, entry) {
            this.checkLangs()
            if(isVisible) {
                this.autoPlayAccounts = true
            } else {
                this.autoPlayAccounts = false
            }
        },
        visibilityStatsChanged(isVisible, entry) {
            this.checkLangs()
            if(isVisible) {
                this.autoPlayStats = true
            } else {
                this.autoPlayStats = false
            }
        },
        visibilityReporteChanged(isVisible, entry) {
            this.checkLangs()
            if(isVisible) {
                this.autoPlayReporte = true
            } else {
                this.autoPlayReporte = false
            }
        },
        checkLangs() {
            const newVal = this.$i18n.locale

            if (newVal === 'pt') {
                this.mapJson = mapJsonPt
                this.statsJson = statsJson
                this.reporteJson = reporteJsonPt
                this.calendarJson = calendarJsonPt
                this.accountsJson = accountsJsonPt
            } else if (newVal === 'en') {
                this.mapJson = mapJsonEn
                this.statsJson = statsJsonEn
                this.reporteJson = reporteJsonEn
                this.calendarJson = calendarJsonEn
                this.accountsJson = accountsJsonEn
            } else {
                this.mapJson = mapJson
                this.statsJson = statsJson
                this.reporteJson = reporteJson
                this.calendarJson = calendarJson
                this.accountsJson = accountsJson
            }
        }
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if (newVal === 'pt') {
                this.mapJson = mapJsonPt
                this.statsJson = statsJson
                this.reporteJson = reporteJsonPt
                this.calendarJson = calendarJsonPt
                this.accountsJson = accountsJsonPt
            } else if (newVal === 'en') {
                this.mapJson = mapJsonEn
                this.statsJson = statsJsonEn
                this.reporteJson = reporteJsonEn
                this.calendarJson = calendarJsonEn
                this.accountsJson = accountsJsonEn
            } else {
                this.mapJson = mapJson
                this.statsJson = statsJson
                this.reporteJson = reporteJson
                this.calendarJson = calendarJson
                this.accountsJson = accountsJson
            }
        }
    }
}
</script>

<style>
@import './section-two.css';
</style>
