<template>
    <aside :style="cssVariables">
        <h3 class="general-component--header--text">{{ title }}</h3>
    </aside>
</template>

<script>
export default {
    name: 'HeaderWithOval',
    props: {
        title: {
            type: String,
            required: true
        },
        textSize: {
            type: String,
            default: '30pt'
        },
        backgroundSize: {
            type: String,
            required:true
        }
    },
    computed: {
        cssVariables() {
            return {
                '--general-font': this.textSize,
                '--background-size': this.backgroundSize
            }
        }
    }
}
</script>

<style>
.general-component--header--text {
    font-size: var(--general-font);
    font-family: 'BrotherBlod', Courier, monospace;
    color: #000000;
    white-space: nowrap ;
    margin: 0;
    width: 150px;
    background-image: url(../../assets/OvaloYellow.png);
    background-position: initial;
    background-repeat: no-repeat;
    background-size: var(--background-size);
}
@media (max-width: 800px) {
    .general-component--header--text {
        width: 80px;
        background-size: 25px;
        font-size: 16pt;
    }
}
</style>
