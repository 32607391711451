<template>
    <aside :style="isWithoutLine ? withOutLine : withLine" class="logo--sized">
        <HeaderOptions></HeaderOptions>
        <Vue3Lottie v-if="isWithoutLine" class="position--lines--full utils-s1--tablet" :loop="false" :animationData="linesJson" :autoPlay="true" />
        <img class="smart-logo--position" alt="Smart Logistic" src="../../assets/SmartLogistic.png" />
        <LetStart class="let-start--position"></LetStart>
    </aside>
</template>

<script>
import linesJson from '../../assets/lottieAnimations/LineFull.json'
import LetStart from './components/letStart.vue'
import HeaderOptions from './components/headerOptions.vue';

export default {
    name: 'SectionOne',
    components: {
        HeaderOptions,
        LetStart,
    },
    data() {
        return {
            linesJson,
            isWithoutLine: true,
            color: '#f4fa73',
            btnName: 'Empieza',
            textColor: '#000000',
        }
    },
    computed: {
        withOutLine() {
            return { 
                backgroundImage: `url(${require('../../assets/SectionOneHeader.jpg')})`
            }
        },
        withLine() {
            return { 
                backgroundImage: `url(${require('../../assets/S1BackgroundLine.jpg')})`
            }
        }
    }
}
</script>

<style>
@import './section-one.css';
.position--lines--full {
    padding-top: 31vh;
    width: 1800px;
}
</style>
