<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body {
  margin: 0px;
}
.padding-sectiontwo {
  padding-top: 200px;
}
.padding-sectionSeven {
  padding-top: 30px;
}
.--bold {
  font-weight: bold;
}
br {
  display: block;
  margin: 10px 0;
  line-height: 22px;
}
.text--align--s7 {
  text-align: center;
}

@media (max-width: 1010px) {
  .padding-sectiontwo {
    padding-top: 100px;
  }
  .padding-sectionSeven {
    padding-top: 15px;
  }
}
@media (max-width: 750px) {
  .utils-general--mobile-s4 {
    display: none;
  }
}
@media (max-width: 484px) {
  .utils-general--mobile {
    display: none;
  }
  .padding-sectiontwo {
    padding-top: 63px;
  }
  .padding-sectionSeven {
    padding-top: 7.5px;
  }
}
@media (min-width: 485px) {
  .utils-general-show--tablet {
    display: none;
  }
}

@font-face {
  font-family: "BrotherBlack";
  src: local("BrotherBlack"),
   url(./fonts/Brother1816-Black.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherBlackItalic";
  src: local("BrotherBlackItalic"),
   url(./fonts/Brother1816-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherBlod";
  src: local("BrotherBold"),
   url(./fonts/Brother1816-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherBlodItalic";
  src: local("BrotherBlodItalic"),
   url(./fonts/Brother1816-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherBook";
  src: local("BrotherBook"),
   url(./fonts/Brother1816-Book.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherBookItalic";
  src: local("BrotherBookItalic"),
   url(./fonts/Brother1816-BookItalic.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherExtraBold";
  src: local("BrotherExtraBold"),
   url(./fonts/Brother1816-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherExtraBoldItalic";
  src: local("BrotherExtraBoldItalic"),
   url(./fonts/Brother1816-ExtraBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherLight";
  src: local("BrotherLight"),
   url(./fonts/Brother1816-Light.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherLightItalic";
  src: local("BrotherLightItalic"),
   url(./fonts/Brother1816-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherMedium";
  src: local("BrotherMedium"),
   url(./fonts/Brother1816-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherMediumItalic";
  src: local("BrotherMediumItalic"),
   url(./fonts/Brother1816-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherRegular";
  src: local("BrotherRegular"),
   url(./fonts/Brother1816-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherRegularItalic";
  src: local("BrotherRegularItalic"),
   url(./fonts/Brother1816-Regulartalic.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherThin";
  src: local("BrotherThin"),
   url(./fonts/Brother1816-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "BrotherThinItalic";
  src: local("BrotherThinItalic"),
   url(./fonts/Brother1816-ThinItalic.ttf) format("truetype");
}
</style>
