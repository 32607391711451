<template>
    <section id="section1" class="s4-container--styled --force-margin--section1">
        <h2 class="text-header--styled --reduce-line-height-s5 utils-s1--tablet">{{ $t("message.s4-title--desktop-p1") }}<br> {{ $t("message.s4-title--desktop-p2") }}</h2>
        <h2 class="text-header--styled --reduce-line-height-bigger utils-desktop">{{ $t("message.s4-title--mobile-p1") }}<br> {{ $t("message.s4-title--mobile-p2") }}<br>{{ $t("message.s4-title--mobile-p3") }}</h2>
        <div class="center--auto title--s5--margin">
            <HeaderWithCicle class="title--styled-s7" textSize="22pt" backgroundSize="40px" title="SOLUCIONES"></HeaderWithCicle>
        </div>
        <!-- Information showed are different on mobile -->
        <section class="card-s4 card-container--styled utils-s1--tablet">
            <ColumnText
                title="Route Planner"
                :index="0"
                :subtitles="[$t('message.s4-column-text--p2'), $t('message.s4-column-text--p1'), $t('message.s4-column-text--p4'), $t('message.s4-column-text--p3')]" />
            <div class="card--line"></div>
            <ColumnText
                title="Last Mile Software"
                :index="1"
                :subtitles="[$t('message.s4-column-text--p8'), $t('message.s4-column-text--p9'), $t('message.s4-column-text--p5'), $t('message.s4-column-text--p6'), $t('message.s4-column-text--p7')]" />
            <div class="card--line"></div>
            <ColumnText
                title="Orkestra 3PL"
                :index="2"
                :subtitles="[$t('message.s4-column-text--p11'), $t('message.s4-column-text--p12'), $t('message.s4-column-text--p10')]" />
            <div class="card--line"></div>
            <ColumnText
                :index="3"
                :subtitles="[$t('message.s4-column-text--p11'), $t('message.s4-column-text--p12'), $t('message.s4-column-text--p10')]" />
        </section>
        <section class="s4--mobile-cards-container utils-desktop">
            <Card>
                <div class="first-card--style">
                    <h3 class="text-header-card--styled">Route Planner</h3>
                    <Vue3Lottie :key="autoPlayRoute" v-observe-visibility="visibilityRouteChanged" class="cursor--pointer img-mobile--resized" :loop="false" :animationData="routeJsonMobile" :autoPlay="autoPlayRoute" />
                    <div class="--gap-s4--mobile">
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p1") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p2") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p3") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p4") }}</p>
                    </div>
                    <GreenButton />
                </div>
            </Card>
            <Card>
                <div class="first-card--style">
                    <h3 class="text-header-card--styled">Last Mile Software</h3>
                    <Vue3Lottie :key="autoPlayLastMile" v-observe-visibility="visibilityLastMileChanged" class="cursor--pointer img-mobile--resized" :loop="false" :animationData="lastMileJsonMobile" :autoPlay="autoPlayLastMile" />
                    <div class="--gap-s4--mobile">
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p5") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p6") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p7") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p8") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p9") }}</p>
                    </div>
                    <GreenButton />
                </div>
            </Card>
            <Card>
                <div class="first-card--style">
                    <h3 class="text-header-card--styled">Orkestra 3PL</h3>
                    <Vue3Lottie :key="autoPlayThreePl" v-observe-visibility="visibilityThreeChanged" class="cursor--pointer img-mobile--resized" :loop="false" :animationData="threeplJsonMobile" :autoPlay="autoPlayThreePl" />
                    <div class="--gap-s4--mobile">
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p10") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p11") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p12") }}</p>
                    </div>
                    <GreenButton />
                </div>
            </Card>
            <Card>
                <div class="first-card--style">
                    <h3 class="text-header-card--styled">Orkestra <br>Automation & Bots</h3>
                    <Vue3Lottie :key="autoPlayAutomation" v-observe-visibility="visibilityAutomationChanged" class="cursor--pointer img-mobile--resized" :loop="false" :animationData="automationJsonMobile" :autoPlay="autoPlayAutomation" />
                    <div class="--gap-s4--mobile">
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p10") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p11") }}</p>
                        <p class="text-s4--inner">{{ $t("message.s4-column-text--p12") }}</p>
                    </div>
                    <GreenButton />
                </div>
            </Card>
        </section>
    </section>
</template>

<script>
/* eslint-disable no-unused-vars */ 
import ColumnText from './components/ColumnText.vue'
import Card from '../sectionTwo/components/Card.vue'
import GreenButton from '../sectionFour/components/GreenButton.vue'
import HeaderWithCicle from '../generalComponents/HeaderWithCicle.vue'

import threeplJson from '../../assets/lottieAnimations/3pl.json'
import routeJson from '../../assets/lottieAnimations/Route.json'
import lastMileJson from '../../assets/lottieAnimations/LastMileIcon.json'
import automationJson from '../../assets/lottieAnimations/Automation2.json'

import threeplJsonMobile from '../../assets/lottieAnimations/Mobile/3pl_Mobile.json'
import routeJsonMobile from '../../assets/lottieAnimations/Mobile/Route_Mobile.json'
import lastMileJsonMobile from '../../assets/lottieAnimations/Mobile/LastMile_Mobile.json'
import automationJsonMobile from '../../assets/lottieAnimations/Mobile/Automation_Mobile.json'


export default {
    name: 'SectionThree',
    components: {
        ColumnText,
        GreenButton,
        Card,
        HeaderWithCicle
    },
    data() {
        return {
            threeplJson,
            routeJson,
            lastMileJson,
            automationJson,
            threeplJsonMobile,
            routeJsonMobile,
            automationJsonMobile,
            lastMileJsonMobile,
            autoPlayRoute: false,
            autoPlayLastMile: false,
            autoPlayThreePl: false,
            autoPlayAutomation: false
        }
    },
    methods: {
        visibilityRouteChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayRoute = true
            }
        },
        visibilityLastMileChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayLastMile = true
            }
        },
        visibilityThreeChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayThreePl = true
            }
        },
        visibilityAutomationChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlayAutomation = true
            }
        }
    },
}
</script>

<style>
@import './section-four.css';
.--force-margin--section1 {
    padding-top: 100px;
}
</style>
