<template>
    <div :style="cssStyled" class="general-carousel--styled">
        <img v-if="currentPage > 0" class="cursor--pointer" alt="left--arrow" src="../../assets/arrowLeft.png" v-on:click="changePage(currentPage - 1)" />
        <div v-else></div>
        <img v-for="(value, idx) in pictures[currentPage]" v-bind:key="idx" class="img-general--resized-s7" :alt="`${idx}-carousel`" :src="value.src"  />
        <img v-if="currentPage < pictures.length - 1" class="cursor--pointer" alt="rigth--arrow" src="../../assets/arrowRight.png" v-on:click="changePage(currentPage + 1)" />
        <div v-else></div>
    </div>
</template>

<script>
export default {
    name: 'HorizontalCarousel',
    props: {
        perPage: {
            default: 5,
            type: Number
        },
        pictures: {
            required: true,
            type: Array
        },
        automaticMove: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            currentPage: 0,
            asc: true
        }
    },
    computed: {
        cssStyled() {
            return {
                '--pictures': this.perPage
            }
        }
    },
    methods: {
        changePage(newIdx) {
            this.currentPage = newIdx
        }
    },
    mounted() {
        if (this.automaticMove) {
            setInterval(() => {
                if (this.currentPage === this.pictures.length - 1) {
                    this.asc = false
                    this.currentPage = this.currentPage - 1
                } else if (this.currentPage === 0) {
                    this.asc = true
                    this.currentPage = this.currentPage + 1
                } else {
                    if (this.asc) {
                        this.currentPage = this.currentPage + 1
                    } else {
                        this.currentPage = this.currentPage - 1
                    }
                }

            }, 5000)
        }
    }
}
</script>

<style>
.general-carousel--styled {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: 21px repeat(var(--pictures), 1fr) 21px;
}
.img-general--resized-s7 {
    height: 150px;
    width: 150px;
}
</style>
