<template>
    <aside>
        <section class="--force-margin">
            <div class="center--auto">
                <HeaderWithCicle class="title--styled-s7" textSize="18pt" backgroundSize="35px" :title="headerTitle"></HeaderWithCicle>
            </div>
            <section class="container--images-s7">
                <HorizontalCarousel :pictures="perPage === 5 ? images : imagesMobile" :perPage="perPage"/>
            </section>
        </section>
        <div class="line--divisor-section-s8"></div>
    </aside>
</template>

<script>
/* eslint-disable no-unused-vars */
import HeaderWithCicle from '../generalComponents/HeaderWithCicle.vue'
import HorizontalCarousel from '../generalComponents/HorizontalCarousel.vue'

export default {
    name: 'SectionSeven',
    components: {
        HeaderWithCicle,
        HorizontalCarousel
    },
    data() {
        return {
            perPage: 5,
            headerTitle: this.$t("message.header-clients").toUpperCase(),
            images: [
                [
                    { src: require('../../assets/companies/AHUMADA.png')},
                    { src: require('../../assets/companies/AMAZON.png')},
                    { src: require('../../assets/companies/AZAP.png')},
                    { src: require('../../assets/companies/BANCO_PROMERICA.png')},
                    { src: require('../../assets/companies/CEMPLY.png')},
                ],
                [
                    { src: require('../../assets/companies/CLARO.png')},
                    { src: require('../../assets/companies/CRESCORP.png')},
                    { src: require('../../assets/companies/FALABELLA.png')},
                    { src: require('../../assets/companies/MOVILBUS.png')},
                    { src: require('../../assets/companies/PETZ.png')},
                ],
                [
                    { src: require('../../assets/companies/RIACHUELO.png')},
                    { src: require('../../assets/companies/SANTA_ELENA.png')},
                    { src: require('../../assets/companies/TRANSSKY.png')},
                    { src: require('../../assets/companies/VERIS.png')},
                ]
            ],
            imagesMobile: [
                [
                    { src: require('../../assets/companies/AHUMADA.png')},
                    { src: require('../../assets/companies/AMAZON.png')},
                ],
                [
                    { src: require('../../assets/companies/AZAP.png')},
                    { src: require('../../assets/companies/BANCO_PROMERICA.png')},
                ],
                [
                    { src: require('../../assets/companies/CEMPLY.png')},
                    { src: require('../../assets/companies/CLARO.png')},
                ],
                [
                    { src: require('../../assets/companies/CRESCORP.png')},
                    { src: require('../../assets/companies/FALABELLA.png')},
                ],
                [
                    { src: require('../../assets/companies/MOVILBUS.png')},
                    { src: require('../../assets/companies/PETZ.png')},
                ],
                [
                    { src: require('../../assets/companies/RIACHUELO.png')},
                    { src: require('../../assets/companies/SANTA_ELENA.png')},
                ],
                [
                    { src: require('../../assets/companies/TRANSSKY.png')},
                    { src: require('../../assets/companies/VERIS.png')},
                ]
            ]
        }
    },
    mounted() {
        this.resizeHandle()
        window.addEventListener("resize", this.resizeHandle);
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeHandle);
    },
    methods: {
        resizeHandle() {
            if (document.documentElement.clientWidth <= 800) {
                this.perPage = 2
            } else {
                this.perPage = 5
            }
        }
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            this.headerTitle = this.$t("message.header-clients").toUpperCase()
        }
    }
}
</script>

<style>
.--force-margin {
    padding: 100px 0px;
}
.title--styled-s7 {
    margin: 0 auto;
}
.--force--padding-s7 {
    padding-top: 70px;
}
.center--auto {
    display: flex;
}
.container--images-s7 {
    padding: 40px 250px;
}
.line--divisor-section-s8 {
    margin: 30px 0px;
    margin-left: 17.5%;
    height: 1px;
    background-color: #000000;
    width: 65%;
}

@media (max-width: 800px) {
    .container--images-s7 {
        padding: 10px 30px;
    }
    .center--auto {
        display: flex;
        margin-left: -70px;
    }
}
@media (max-width: 680px) {
    .title--styled-s7 {
        padding-bottom: 140px;
    }
    .container--images-s7 {
        margin-bottom: 70px;
    }
}
</style>
