<template>
    <div class="general-btn--styled" :style="cssBackgroundColor">
        <p :style="cssTextColor" class="text-btn--styled">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: 'GeneralButon',
    props: {
        color: {
            type: String,
            default: '#f4fa73'
        },
        textColor: {
            type: String,
            default: '#000000'
        },
        text: {
            type: String,
            default: ''
        },
        sized: {
            type: String,
            default: '150px'
        },
        textSize: {
            type: String,
            default: '12pt'
        }
    },
    computed: {
        cssTextColor() {
            return {
                'color': this.textColor
            }
        },
        cssBackgroundColor() {
            return {
                'background-color': this.color,
                '--sized': this.sized,
                '--text-sized': this.textSize
            }
        }
    }
}
</script>

<style>
.general-btn--styled {
    width: var(--sized);
    border-radius: 25px;
    margin-top: 1px;
}
.text-btn--styled {
    font-size: var(--text-sized);
    cursor: pointer;
    font-weight: bold;
    font-family: 'BrotherMedium', Courier, monospace;
    white-space: nowrap;
    margin: 0rem;
    padding: .5rem 0rem .5rem 0rem;
}
</style>
