import { createStore } from 'vuex'

const simpleStore = {
    state () {
      return {
        isChangingLang: false
      }
    },
    mutations: {
      openCloseLanOptions (state) {
        state.isChangingLang = !state.isChangingLang
      },
      forceValueLang (state, value) {
        state.isChangingLang = value
      }
    }
}

export default createStore(simpleStore)
