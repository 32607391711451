import { createRouter, createWebHistory } from 'vue-router'

import PrivacyPolicy from './components/routes/PrivacyPolicy'
import NotFound from './components/routes/NotFound'
import Home from './components/routes/Home'

const routes = [
    { path: '/privacy', component: PrivacyPolicy },
    { path: '/notFound', component: NotFound },
    { path: '/', component: Home },
    
]
  

export default createRouter({
    history: createWebHistory(),
    routes,
})
