<template>
    <h2 id="section5" class="text-header--styled --margin-s8--top --reduce-line-height-s5 utils-s1--tablet">{{ $t("message.s8-title--desktop-p1") }}<br>{{ $t("message.s8-title--desktop-p2") }}</h2>
    <h2 id="section5" class="text-header--styled --margin-s8--top --reduce-line-height-bigger utils-desktop">{{ $t("message.s8-title--mobile-p1") }}<br> {{ $t("message.s8-title--mobile-p2") }}<br> {{ $t("message.s8-title--mobile-p3") }}</h2>
    <div class="center--auto">
        <HeaderWithCicle class="title--styled-s7 little-margin--text-s8" textSize="18pt" backgroundSize="35px" title="INDUSTRIAS"></HeaderWithCicle>
    </div>
    <section class="container--img-s8">
        <Vue3Lottie :key="autoPlay" v-observe-visibility="visibilityChanged" class="display--desktop cursor--pointer" :loop="false" :animationData="tagsJson" :autoPlay="autoPlay" />
        <!-- The industries are different for mobile -->
        <img class="industries--tablet-28 hide utils-general--mobile" alt="industries" src="../../assets/images-industries-s8.png" />
        <img class="industries--tablet-28 utils-general-show--tablet" alt="industries" src="../../assets/industries-s8--mobile.png" />
    </section>
</template>

<script>
/* eslint-disable no-unused-vars */
import HeaderWithCicle from '../generalComponents/HeaderWithCicle.vue'

// Spanish
import tagsJson from '../../assets/lottieAnimations/Tags.json'
// English
import tagsJsonEn from '../../assets/lottieAnimations/en/Tags.json'
// Portugues
import tagsJsonPt from '../../assets/lottieAnimations/pt/Tags.json'

export default {
    name: 'SectionEigth',
    components: {
        HeaderWithCicle
    },
    data() {
        return {
            tagsJson
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        visibilityChanged(isVisible, entry) {
            if (isVisible) {
                this.autoPlay = true
            }
        }
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if (newVal === 'pt') {
                this.tagsJson = tagsJsonPt
            } else if (newVal === 'en') {
                this.tagsJson = tagsJsonEn
            } else {
                this.tagsJson = tagsJson
            }
        }
    }
}
</script>

<style>
h2 {
    margin: 0;
}
.--margin-s8--top {
    padding-top: 80px;
}
.container--img-s8 {
    gap: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.display--desktop {
    display: block;
    margin-bottom: 90px;
}
.little-margin--text-s8 {
    margin: 20px auto;
}
@media (min-width: 981px) {
    .hide {
        display: none;
    }
}
@media (max-width: 980px) {
    .industries--tablet-28 {
        width: 80%;
    }
    .display--desktop {
        display: none;
    }
}
@media (max-width: 484px) {
    .industries--tablet-28 {
        width: 90%;
    }
}
</style>
