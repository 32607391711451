<template>
    <div class="cursor--pointer button-s4--styled" v-on:click="redirectURL">
        <p class="tablet--size--value">Solicitar Demo</p>
    </div>
</template>

<script>
export default {
    name: 'GreenButton',
    methods: {
        redirectURL() {
            window.open('https://forms.gle/3XKBn4ngjhtk4Ec46')
        }
    }
}
</script>

<style>
.button-s4--styled {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 8px 30px 4px 30px;
    border-radius: 25px;
    background-color: #45ff71;
}
.tablet--size--value {
    font-size: 14pt;
    font-weight: bold;
    font-family: 'BrotherBlod', Courier, monospace;
}
@media (max-width: 1010px) {
    .button-s4--styled {
        padding: 1px 15px;
    }
    .tablet--size--value {
        font-size: 12pt;
    }
}
@media (max-width: 800px) {
    .button-s4--styled {
        padding: .5px 5.5px;
    }
    .tablet--size--value {
        font-size: 8pt;
    }
}
@media (max-width: 484px) {
    .button-s4--styled {
        padding: 2px 30px;
    }
    .tablet--size--value {
        font-size: 14pt;
    }
}
</style>
