<template>
    <aside class="image--background-s5">
        <SectionFour></SectionFour>
        <SectionFive></SectionFive>
    </aside>
</template>

<script>
import SectionFour from '../sectionFour/SectionFour.vue'
import SectionFive from '../sectionFive/SectionFive.vue'

export default {
    name: 'JoinSections',
    components: {
        SectionFour,
        SectionFive
    }
}
</script>
