<template>
    <div class="grid-let-start--style">
        <p class="utils-s1--tablet text-header--styled ---force-color--text--banner">{{ bannerName }} <span :style="{ fontStyle: 'italic' }">{{ bannerName2 }}</span></p>
        <img class="img-banner--size utils-desktop" alt="Tu Logistica Banner" src="../../../assets/tu-logistica-s1-tablet.png"/>
        <GeneralButton v-on:click="openUrl" class="align-right" :text="btnName" textSize="20pt" sized="210px"></GeneralButton>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import GeneralButton from '../../generalComponents/Button.vue'

export default {
    components: {
        GeneralButton
    },
    data() {
        return {
            btnName: this.$t("message.s1--button-start"),
            bannerName: this.$t("message.s1--title--button1"),
            bannerName2: this.$t("message.s1--title--button2")
        }
    },
    methods: {
        openUrl() {
            window.open('https://forms.gle/8nWgKpD3soHffVbW6')
        }
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            this.btnName = this.$t("message.s1--button-start")
            this.bannerName = this.$t("message.s1--title--button1")
            this.bannerName2 = this.$t("message.s1--title--button2")
        }
    }
}
</script>

<style>
.---force-color--text--banner {
    color: white !important;
    font-size: 31.2pt !important;
}
.img-banner--size {
    width: 600px;
}
.grid-let-start--style {
    display: grid;
    align-items: end;
    gap: 24px;
    grid-template-rows: repeat(2, 1fr);
}
.logistic-let-start--styled {
    color: white;
    font-weight: bold;
    font-family: 'BrotherMedium', Courier, monospace;
    white-space: nowrap;
    font-size: 32pt;
    margin: 0;
}
.align-right {
    margin-left: auto;
}
@media (max-width: 1010px) {
    .grid-let-start--style {
        gap: 0px;
        align-items: center;
        align-content: center;
    }
    .img-banner--size {
        width: 500px;
    }
    .img-button--resized {
        padding-left: 40%;
    }
}
@media (max-width: 800px) {
    .img-banner--size {
        width: 400px;
    }
    .img-button--resized {
        padding-left: 30%;
    }
    .align-right {
        margin: auto;
    }
    .grid-let-start--style {
        grid-template-rows: 1fr 100px;
    }
}
@media (max-width: 680px) {
    .img-banner--size {
        width: 40vw;
    }
}
@media (max-width: 484px) {
    .img-banner--size {
        width: 95%;
    }
    .img-button--resized {
        padding-left: 25%;
        width: 180px;
    }
}
</style>